import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SwipeableButton from 'components/buttons/swipeableButton/SwipeableButton'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { PlayerImage } from 'components/patterns/PlayerImage'
import {
  AlertError,
  AlertLocked,
  AlertPlayerSelect,
} from 'components/design-elements/Notifications'
import { delay } from 'utils/delay'
import { getCurrentDateTime, getConvertedDateTime, subtractHours } from 'hooks/useTimeConvert'
import assignTeam from '../utils/assignTeam'
import { Card } from 'components/design-elements/Card.styled'
import Switch from 'react-switch'
import SuccessAnimation from 'components/loader/SuccessAnimation'
import useVersionFlag from 'hooks/useVersionFlag'
import useCanUpdateTeam from 'hooks/useCanUpdateTeam'
import { teamChangeCutOff } from 'settings/config'
import {
  TeamRowContainer,
  Keeper,
  UpdateTeams,
  SwitchContainer,
  TeamMgmtGrid,
  TeamMgmTitle,
  TeamMgmCopy,
  TeamMgmCopyTime,
  Pitch,
  PitchContainer,
  TeamStarters,
  SubsSection,
  TeamStartersContainer,
  TeamRow,
  PlayerContainer,
  Fixture,
  Name,
  NameInBid,
} from '../Teams.styles'

/**
 * Component for managing the user's team.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.defendersArray - The array of defenders.
 * @param {Array} props.midfieldArray - The array of midfielders.
 * @param {Array} props.forwardArray - The array of forwards.
 * @param {Array} props.subsArray - The array of substitutes.
 * @param {Object} props.userTeamData - The data of the user's team.
 * @param {string} props.userName - The username of the user.
 * @param {string} props.timeStampRead - The timestamp for reading.
 * @param {Function} props.refetch - The function to refetch data.
 * @returns {JSX.Element} The ManageMyTeam component.
 */
export default function ManageMyTeam({
  defendersArray,
  midfieldArray,
  forwardArray,
  subsArray,
  userTeamData,
  userName,
  timeStampRead,
  refetch,
}) {
  const columnsFromBackend = {
    DEF: {
      name: 'Defenders',
      items: defendersArray,
    },
    MID: {
      name: 'Midfield',
      items: midfieldArray,
    },
    FWD: {
      name: 'Forwards',
      items: forwardArray,
    },
    SUB: {
      name: 'Subs',
      items: subsArray,
    },
  }

  const [columns, setColumns] = useState(columnsFromBackend)
  const [position, setPosition] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [message, setMessage] = useState('')
  const [checked, setChecked] = useState(false)
  const { isUK, SPREADSHEET_ID, CLIENT_EMAIL, PRIVATE_KEY } = useVersionFlag()
  const { canUpdateTeam, gwGameDayUTC } = useCanUpdateTeam()

  const keeperImg = userTeamData[0].kepimg
  const keeperName = userTeamData[0].kepln
  const keeperNextGame = userTeamData[0].kepng
  const keeperBid = userTeamData[0].kepBid

  // gets the current date and time in Lon, NY and UTC
  const { currentDateInLondon, currentDateInNewYork, currentTimeInLondon, currentTimeInNewYork } =
    getCurrentDateTime()

  const localCutOff = isUK ? teamChangeCutOff.lon.hours : teamChangeCutOff.ny.hours

  const subsLockTimeUTC = subtractHours(gwGameDayUTC, localCutOff)
  const { timeLondon, timeNY, dateLondon, dateNY } = getConvertedDateTime(subsLockTimeUTC)

  const timeStampPost = isUK
    ? `${currentDateInLondon}, ${currentTimeInLondon}`
    : `${currentDateInNewYork}, ${currentTimeInNewYork}`

  /*======================================================
    All of the below manages the updating of the current
    logged in users row in the POST_Teams tab by reading
    the most current array in state and then posting it using
    the GoogleSpreadsheet depedendency
  =======================================================*/

  const SHEET_ID = process.env.REACT_APP_SHEET_ID_POST_TEAMS
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

  const appendSpreadsheet = async row => {
    try {
      setMessage('')
      setErrorMessage('')
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      })

      await doc.loadInfo()

      const sheet = doc.sheetsById[SHEET_ID]
      const rows = await sheet.getRows()

      rows.forEach((el, index) => {
        if (el.manager === userName) {
          rows[index].manager = null
          rows[index].def1 = def1string
          rows[index].def2 = def2string
          rows[index].def3 = def3string
          rows[index].def4 = def4string
          rows[index].def5 = def5string
          rows[index].mid1 = mid1string
          rows[index].mid2 = mid2string
          rows[index].mid3 = mid3string
          rows[index].mid4 = mid4string
          rows[index].mid5 = mid5string
          rows[index].fwd1 = fwd1string
          rows[index].fwd2 = fwd2string
          rows[index].fwd3 = fwd3string
          rows[index].sub1 = sub1string
          rows[index].sub2 = sub2string
          rows[index].sub3 = sub3string
          rows[index].lastUpdatedPost = timeStampPost
          rows[index].save()
        }
      })
      setMessage('🚀 Submitted!')
      refetch()
      await delay(5000)
      setMessage('')
    } catch (e) {
      setErrorMessage('Whoops! something went wrong. Please contact your local admin.')
      console.error('Error: ', e)
      await delay(3000)
      setErrorMessage('')
    }
  }

  let {
    def1string,
    def2string,
    def3string,
    def4string,
    def5string,
    mid1string,
    mid2string,
    mid3string,
    mid4string,
    mid5string,
    fwd1string,
    fwd2string,
    fwd3string,
    sub1string,
    sub2string,
    sub3string,
  } = assignTeam(columns)

  /*======================================================
    onDragStart grabs the current draggables Position, 
    and stores that in state (position) which then tells
    the droppables to disable when the draggables position
    doesn't match the droppable

    onDragEnd manages the updating of state once the draggable
    has been dropped
  =======================================================*/

  const onDragStart = result => {
    const teamArray = [...defendersArray, ...midfieldArray, ...forwardArray, ...subsArray].filter(
      player => player.id === result.draggableId
    )
    setPosition(teamArray[0].pos)
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
    }
  }

  const handleSwitchChange = nextChecked => {
    setChecked(nextChecked)
  }

  return (
    <>
      <Card>
        <TeamStarters padding='0'>
          <PitchContainer>
            <Pitch src='/img/assets/pitch.svg' alt='pitch' />
          </PitchContainer>
          <SubsSection />
          <TeamStartersContainer>
            <Keeper>
              <PlayerImage source={keeperImg} width='15vw' maxWidth='100px' margin='0 1vw' />
              {!checked && (
                <>
                  {keeperBid !== 'TRUE' ? (
                    <Name>{keeperName}</Name>
                  ) : (
                    <NameInBid>{keeperName}</NameInBid>
                  )}
                </>
              )}
              {checked && <Fixture>{keeperNextGame}</Fixture>}
            </Keeper>
            {/*
/////////////////////////////////////////

CONTAINER OF PLAYER ROWS

///////////////////////////////////////// 
*/}
            <DragDropContext
              onDragEnd={result => onDragEnd(result, columns, setColumns)}
              onDragStart={result => onDragStart(result)}
            >
              {Object.entries(columns).map(([id, column]) => {
                return (
                  <TeamRowContainer key={id}>
                    <Droppable
                      droppableId={id}
                      key={id + column}
                      direction='horizontal'
                      isDropDisabled={
                        id === 'SUB' && columns.SUB.items.length >= 3
                          ? true
                          : id === 'SUB'
                          ? false
                          : position !== id
                          ? true
                          : false
                      }
                    >
                      {/*
/////////////////////////////////////////

SINGLE ROW OF PLAYERS

///////////////////////////////////////// 
*/}

                      {(provided, snapshot) => {
                        return (
                          <TeamRow
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            key={id}
                            isDraggingOver={snapshot.isDraggingOver}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                  isDragDisabled={
                                    columns.DEF.items.length <= 3 &&
                                    item.pos === 'DEF' &&
                                    item.pos === id
                                      ? true
                                      : columns.MID.items.length <= 3 &&
                                        item.pos === 'MID' &&
                                        item.pos === id
                                      ? true
                                      : columns.FWD.items.length <= 1 &&
                                        item.pos === 'FWD' &&
                                        item.pos === id
                                      ? true
                                      : false
                                  }
                                >
                                  {/*
/////////////////////////////////////////

SINGLE PLAYER CONTAINER

///////////////////////////////////////// 
*/}

                                  {(provided, snapshot) => {
                                    return (
                                      <PlayerContainer
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={item.imgId}
                                        isDragging={snapshot.isDragging}
                                      >
                                        <PlayerImage
                                          key={item.imgId}
                                          source={item.imgId}
                                          width='15vw'
                                          maxWidth='100px'
                                          margin='0 1vw'
                                        />
                                        {!checked && (
                                          <>
                                            {item.bid !== 'TRUE' ? (
                                              <Name>{item.lastName}</Name>
                                            ) : (
                                              <NameInBid>{item.lastName}</NameInBid>
                                            )}
                                          </>
                                        )}
                                        {checked && (
                                          <Fixture>{item.nextGame.replace('+', '+\n')}</Fixture>
                                        )}
                                      </PlayerContainer>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </TeamRow>
                        )
                      }}
                    </Droppable>
                  </TeamRowContainer>
                )
              })}
            </DragDropContext>
          </TeamStartersContainer>
        </TeamStarters>
      </Card>

      {/*
/////////////////////////////////////////

UPDATE TEAMS SECTION

///////////////////////////////////////// 
*/}

      <UpdateTeams>
        {canUpdateTeam ? (
          <>
            <div style={{ height: '85px' }}>
              {columns.SUB.items.length < 3 ? (
                <AlertPlayerSelect>
                  <span>
                    Add{' '}
                    {columns.SUB.items.length < 1
                      ? '3'
                      : columns.SUB.items.length < 2
                      ? '2'
                      : columns.SUB.items.length < 3
                      ? '1'
                      : null}{' '}
                    to your bench
                  </span>
                </AlertPlayerSelect>
              ) : (
                <>
                  {!errorMessage && !message ? (
                    <SwipeableButton
                      text='UPDATE TEAM'
                      text_unlocked='UPDATING...'
                      onSuccess={async () => {
                        appendSpreadsheet()
                      }}
                    />
                  ) : null}
                  {message && <SuccessAnimation message={'Updated'} />}
                  {errorMessage && <AlertError>Something went wrong</AlertError>}
                </>
              )}
            </div>

            {/*=======================================================*/}

            <TeamMgmtGrid>
              <TeamMgmTitle>Last updated</TeamMgmTitle>
              <TeamMgmTitle>Deadline</TeamMgmTitle>
              <TeamMgmTitle>Show fixtures</TeamMgmTitle>

              <TeamMgmCopy>
                {timeStampRead ? (
                  <>
                    {timeStampRead.split(', ')[0]}
                    <br />
                    <TeamMgmCopyTime>{timeStampRead.split(', ')[2]}</TeamMgmCopyTime>
                    <br />
                    {timeStampRead.split(', ')[1]}
                  </>
                ) : (
                  <div style={{ color: '#aaa', fontStyle: 'italic' }}>
                    you have not updated your team yet
                  </div>
                )}
              </TeamMgmCopy>

              <TeamMgmCopy>
                {isUK ? dateLondon.split(', ')[0] : dateNY.split(', ')[0]}
                <br />
                <TeamMgmCopyTime>{isUK ? timeLondon : timeNY}</TeamMgmCopyTime>
                <br />
                {isUK ? dateLondon.split(', ')[1] : dateNY.split(', ')[1]}
              </TeamMgmCopy>

              <SwitchContainer>
                <Switch
                  onChange={handleSwitchChange}
                  checked={checked}
                  onColor='#8d8bb9'
                  onHandleColor='#4f46e5'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={20}
                  width={48}
                  className='react-switch'
                  id='material-switch'
                />
              </SwitchContainer>
            </TeamMgmtGrid>

            {/*=======================================================*/}
          </>
        ) : (
          <AlertLocked>Updates locked</AlertLocked>
        )}
      </UpdateTeams>
    </>
  )
}
