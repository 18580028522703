import { useContext } from 'react'
import { VersionFlags } from 'contexts/FlagsContext'

export default function useVersionFlag() {
  const { countryFlag } = useContext(VersionFlags)

  const isUK = countryFlag.country === 'UK'

  const SPREADSHEET_ID = isUK
    ? process.env.REACT_APP_SPREADSHEET_ID
    : process.env.REACT_APP_SPREADSHEET_ID_USA

  const CLIENT_EMAIL = isUK
    ? process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL
    : process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL_USA

  const PRIVATE_KEY = isUK
    ? process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(/\\n/g, '\n')
    : process.env.REACT_APP_GOOGLE_PRIVATE_KEY_USA.replace(/\\n/g, '\n')

  return { isUK, SPREADSHEET_ID, CLIENT_EMAIL, PRIVATE_KEY }
}
