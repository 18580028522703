import * as React from 'react'
const SvgStar = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={11} height={12} fill='none' {...props}>
    <path
      fill='#DFB966'
      d='M2.138 7.176c.128.13.186.318.156.503l-.466 2.84c-.076.464.39.818.788.6l2.439-1.342a.52.52 0 0 1 .505 0L8 11.118c.398.22.863-.135.787-.598l-.466-2.841a.58.58 0 0 1 .157-.503l1.973-2.012c.322-.328.144-.9-.301-.968L7.423 3.78a.55.55 0 0 1-.409-.31L5.794.886a.533.533 0 0 0-.973 0L3.6 3.47a.55.55 0 0 1-.409.31l-2.727.415c-.445.067-.623.64-.3.968z'
    />
  </svg>
)
export default SvgStar
