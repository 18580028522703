import { useState } from 'react'
import ReactModal from 'react-modal'
import useFixedScroll from './useFixedScroll'
import styled from 'styled-components'

/*======================
Master Modal styles
====================== */
const border = 'none'
const left = '3vw'
const right = '3vw'
const backgroundColor = 'var(--color-grey-2)'
const borderRadius = 'var(--box-radius)'
const overflow = 'visible'
const overlay = {
  backgroundColor: '#00000090',
  zIndex: '20000',
}

/*======================
Auction bid confirmation modal
====================== */

const fullModal = {
  content: {
    top: '60px',
    bottom: '3vw',
    padding: 'none',
    right: right,
    left: left,
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    border: border,
    overflow: overflow,
  },
  overlay: overlay,
}

const fitToContentModal = {
  content: {
    top: '50%',
    left: '50%',
    width: '90%',
    height: 'fit-content',
    transform: 'translate(-50%, -50%)',
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    border: border,
    overflow: overflow,
  },
  overlay: overlay,
}

const CloseModal = styled.button`
  position: absolute;
  top: -45px;
  right: 0;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  border: 2px solid #696969;
  background: #1a1a1a;
  color: white;
  z-index: 9;
`

export default function useModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  useFixedScroll(modalIsOpen)

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  return {
    ReactModal,
    CloseModal,
    setModalIsOpenToTrue,
    setModalIsOpenToFalse,
    modalIsOpen,
    fullModal,
    fitToContentModal,
  }
}
