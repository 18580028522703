import * as React from 'react'
const SvgNavFfcup = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21} height={21} fill='none' {...props}>
    <path
      fill='currentColor'
      d='M20.955 4.73c-.228-1.507-1.09-2.48-2.197-2.48h-2.252V1.5c0-.827-.674-1.5-1.502-1.5H5.996c-.828 0-1.502.673-1.502 1.5v.75H2.242c-1.108 0-1.97.973-2.197 2.48C-.2 6.367.571 8.046 2.011 9.012l2.816 1.885c.038.025.08.04.12.056a4.51 4.51 0 0 0 3.3 2.479v3.067h-.914c-.69 0-1.29.468-1.457 1.137l-.376 1.5c-.111.452-.012.921.274 1.287S6.492 21 6.957 21h7.086c.465 0 .896-.21 1.183-.577.286-.366.386-.835.273-1.287l-.376-1.5a1.5 1.5 0 0 0-1.456-1.137h-.915v-3.067a4.51 4.51 0 0 0 3.3-2.479c.041-.017.084-.031.121-.056l2.816-1.885c1.44-.966 2.211-2.645 1.966-4.283M4.495 8.867 2.846 7.764c-.96-.642-1.477-1.746-1.316-2.811.103-.686.409-1.203.711-1.203h2.252zm9.173 9.131.377 1.5H6.957l.376-1.5zm-3.919-1.5v-3h1.502v3zM15.004 9c0 1.655-1.347 3-3.003 3H9a3.004 3.004 0 0 1-3.003-3V1.5h9.008zm3.149-1.234-1.647 1.103V3.75h2.252c.302 0 .608.517.711 1.203.16 1.066-.356 2.17-1.316 2.812'
    />
  </svg>
)
export default SvgNavFfcup
