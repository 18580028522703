import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'components/buttons/Button'

export default function DashQuickLinks({ canUpdateTeam = false, isInAuctionRoom = false }) {
  return (
    <>
      {isInAuctionRoom && canUpdateTeam ? (
        <Grid>
          <Link to='/auction-room'>
            <Button warning>Auction Room</Button>
          </Link>
          <Link to='/teams/my-team'>
            <Button primary>Update team</Button>
          </Link>
        </Grid>
      ) : canUpdateTeam ? (
        <Grid teamUpdateOnly>
          <Link to='/teams/my-team'>
            <Button primary>Update team</Button>
          </Link>
        </Grid>
      ) : null}
    </>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  margin-bottom: 1rem;
  ${props =>
    props.teamUpdateOnly &&
    css`
      grid-template-columns: 1fr;
    `}
`
