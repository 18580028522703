import React from 'react'
import styled from 'styled-components'

export default function Tooltip({ children, openProp }) {
  return <Box onClick={() => openProp.setter(!openProp.state)}>{children}</Box>
}

const Box = styled.div`
  position: absolute;
  cursor: pointer;
  line-height: 1.5;
  width: 50vw;
  font-size: 0.7rem;
  top: 79px;
  right: 43px;
  background: white;
  padding: 0.7rem;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  z-index: var(--zi-tool-tip);
`
