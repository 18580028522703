import React from 'react'
import { useOutletContext } from 'react-router-dom'
import SingleTeam from './SingleTeam'
import { allTeamsFormatter } from '../utils/allTeamsFormatter'

export default function AllTeams() {
  const { teamsData } = useOutletContext()
  const teamArray = allTeamsFormatter(teamsData)

  const teamComponents = teamArray.map(team => (
    <SingleTeam
      key={team.keeper[0].imgId + team.manager}
      manager={team.manager}
      lastUpdated={team.lastUpdated}
      keeper={team.keeper}
      defenders={team.defenders}
      midfielders={team.midfielders}
      forwards={team.forwards}
      subs={team.subs}
    />
  ))

  return teamComponents
}
