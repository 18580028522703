import * as React from 'react'
const SvgIconShortlistAdd = ({ scale = 1 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={68 * scale}
    height={87 * scale}
    viewBox='0 0 68 87'
    fill='none'
  >
    <path fill='#B4B9E3' d='M4.26 5.378h45.654L63.17 18.745v62.878H4.259z' />
    <path
      fill='#3F2A78'
      d='M9.322.54C4.313.54.186 4.702.186 9.754v67.572c0 5.051 4.127 9.214 9.136 9.214h48.727c5.009 0 9.137-4.163 9.137-9.215V18.969c0-.814-.322-1.595-.893-2.171L51.066 1.44a3.03 3.03 0 0 0-2.153-.9zm0 6.143h33.5v9.214c0 5.051 4.127 9.214 9.136 9.214h9.137v52.215c0 1.754-1.306 3.07-3.046 3.07H9.322c-1.74 0-3.046-1.316-3.046-3.07V9.754c0-1.755 1.306-3.071 3.046-3.071m39.59 1.271 10.922 11.014h-7.876c-1.74 0-3.045-1.316-3.045-3.071z'
    />
    <path
      stroke='#3F2A78'
      strokeLinecap='round'
      strokeWidth={6.931}
      d='M18.459 49.683h30.454M33.686 65.04V34.325'
    />
  </svg>
)
export default SvgIconShortlistAdd
