import './checkboxes.css'
import styled from 'styled-components'
import { InputContainer, InputLabels } from './Input.styled'

/*
 *  @param {Array} checkboxes – array of checkbox objects
 *  @param {Object} setChecked – setState object
 */

export default function Checkboxes({ checkboxes, setChecked, inputLabel, columns = '4' }) {
  const handleChange = key => {
    setChecked(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  return (
    <InputContainer>
      <InputLabels>{inputLabel}</InputLabels>
      <CheckboxGroup columns={columns}>
        {checkboxes.map(checkbox => (
          <Checkbox
            key={checkbox.stateKey}
            label={checkbox.label}
            value={checkbox.value}
            onChange={() => handleChange(checkbox.stateKey)}
            svg={checkbox.svg}
          />
        ))}
      </CheckboxGroup>
    </InputContainer>
  )
}

function Checkbox({ label = '', value, onChange, svg }) {
  return (
    <>
      <label>
        <input type='checkbox' className='checkbox-input' checked={value} onChange={onChange} />
        <span className='checkbox-tile'>
          <span className='checkbox-icon'>{svg}</span>
          {label && <span className='checkbox-label'>{label}</span>}
        </span>
      </label>
    </>
  )
}

const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  gap: 1rem;
`
