import React, { createContext, useState, useEffect } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'
import { useAuth } from 'contexts/AuthContext'

export const DataProvider = ({ children }) => {
  const { currentUser } = useAuth()
  const db = getDatabase()
  const getPrivateData = ref(db, '/private_data')
  const getPublicData = ref(db, '/public_data')
  const [privateDb, setPrivateDb] = useState({})
  const [publicDb, setPublicDb] = useState({})
  const [fireBaseisLoading, setFireBaseisLoading] = useState(true)

  useEffect(() => {
    const privateDataListener = onValue(getPrivateData, snapshot => {
      const data = snapshot.val()
      if (data) {
        setPrivateDb(data)
        setFireBaseisLoading(false)
      }
    })
    const publicDataListener = onValue(getPublicData, snapshot => {
      const data = snapshot.val()
      if (data) {
        setPublicDb(data)
      }
    })

    return () => {
      privateDataListener()
      publicDataListener()
    }
  }, [currentUser])

  return (
    <Data.Provider value={{ privateDb, publicDb, fireBaseisLoading }}>{children}</Data.Provider>
  )
}

export const Data = createContext({})
