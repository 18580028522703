import React from 'react'
import styled, { css } from 'styled-components'

export const ThreeDots = () => {
  return (
    <Container>
      <ThreeDotsStyle />
    </Container>
  )
}

export const SpinningCircle = ({ color = '#000', size = '48px' }) => {
  return (
    <Container>
      <SpinningCircleStyle color={color} size={size} />
    </Container>
  )
}

export const AnimatedCard = () => {
  return (
    <Container loadingCard>
      <AnimatedCardStyle />
    </Container>
  )
}

export const CentredSpinningCircle = ({ color }) => {
  return (
    <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
      <div style={{ alignSelf: 'end', marginBottom: '10px' }}>Loading...</div>
      <div style={{ alignSelf: 'start', marginTop: '10px' }}>
        <SpinningCircle color={color} />
      </div>
    </div>
  )
}

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  ${props =>
    props.loadingCard &&
    css`
      position: relative;
      width: auto;
    `}
`

const dotsColor = '#fff'

const ThreeDotsStyle = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${dotsColor};
  box-shadow: 32px 0 ${dotsColor}, -32px 0 ${dotsColor};
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  margin-top: 16px;

  @keyframes flash {
    0% {
      background-color: ${dotsColor + '2'};
      box-shadow: 32px 0 ${dotsColor + '2'}, -32px 0 ${dotsColor};
    }
    50% {
      background-color: ${dotsColor};
      box-shadow: 32px 0 ${dotsColor + '2'}, -32px 0 ${dotsColor + '2'};
    }
    100% {
      background-color: ${dotsColor + '2'};
      box-shadow: 32px 0 ${dotsColor}, -32px 0 ${dotsColor + '2'};
    }
  }
`

const SpinningCircleStyle = styled.span`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  ::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid ${props => props.color};
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`

const AnimatedCardStyle = styled.span`
  width: 100%;
  height: 215px;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);

  ::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 15px);
    border-radius: var(--box-radius);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.5) 50%,
        transparent 100%
      ),
      linear-gradient(#ddd 100px, transparent 0), linear-gradient(#ddd 16px, transparent 0),
      linear-gradient(#ddd 50px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 175px, 100% 100px, 100% 16px, 100% 30px;
    background-position: -185px 0, center 0, center 115px, center 142px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }

  @keyframes animloader {
    to {
      background-position: 185px 0, center 0, center 115px, center 142px;
    }
  }
`
