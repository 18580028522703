import React from 'react'
import styled from 'styled-components'
import useFixedScroll from 'hooks/useFixedScroll'
import 'components/navigation/bottom-nav/bottomNav.css'

export default function Burger({ open, setOpen }) {
  useFixedScroll(open)
  return (
    <>
      <HitArea onClick={() => setOpen(!open)} />
      <AnimatedLines open={open}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </AnimatedLines>
      <Bulge />
    </>
  )
}

const AnimatedLines = styled.div`
  width: 2em;
  height: 42px;
  position: fixed;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;
  right: 21px;
  bottom: 2px;
  @media (min-width: 360px) {
    right: 26px;
  }

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    :nth-child(1) {
      top: ${({ open }) => (open ? '10px' : '0px')};
      width: ${({ open }) => (open ? '0%' : null)};
      left: ${({ open }) => (open ? '50%' : null)};
    }

    :nth-child(2) {
      top: ${({ open }) => (open ? null : '10px')};
      -webkit-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      -moz-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      -o-transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : null)};
      top: ${({ open }) => (open ? '10px' : null)};
    }

    :nth-child(3) {
      top: ${({ open }) => (open ? null : '10px')};
      -webkit-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      -moz-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      -o-transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : null)};
      top: ${({ open }) => (open ? '10px' : null)};
    }

    :nth-child(4) {
      top: ${({ open }) => (open ? '10px' : '20px')};
      width: ${({ open }) => (open ? '0%' : null)};
      left: ${({ open }) => (open ? '50%' : null)};
    }
  }
`

const Bulge = styled.div`
  position: absolute;
  background: #fff;
  height: 110px;
  width: 110px;
  border-radius: 100px;
  bottom: -38px;
  right: -15px;
  z-index: -1;
  @media (max-width: 360px) {
    display: none;
  }
`

const HitArea = styled.div`
  position: absolute;
  background: transparent;
  height: 110px;
  width: 90px;
  bottom: -41px;
  right: -15px;
  z-index: 500;
`
