import { css } from 'styled-components'

const device = {
  width: {
    sm: '375px',
    md: '600px',
    lg: '1280px',
  },
  height: {
    sm: '667px',
    md: '844px',
    lg: '1080px',
  },
}

export const media = {
  // Width
  wsm: (...args) => css`
    @media (max-width: ${device.width.sm}) {
      ${css(...args)};
    }
  `,
  wmd: (...args) => css`
    @media (max-width: ${device.width.md}) {
      ${css(...args)};
    }
  `,
  wlg: (...args) => css`
    @media (max-width: ${device.width.lg}) {
      ${css(...args)};
    }
  `,
  // Height
  hsm: (...args) => css`
    @media (max-height: ${device.height.sm}) {
      ${css(...args)};
    }
  `,
  hmd: (...args) => css`
    @media (max-height: ${device.height.md}) {
      ${css(...args)};
    }
  `,
  hlg: (...args) => css`
    @media (max-height: ${device.height.lg}) {
      ${css(...args)};
    }
  `,
}
