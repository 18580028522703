import styled, { css } from 'styled-components'

export const TableGrid = styled.div`
  display: grid;
  background: #fff;
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  align-items: center;
  font-size: 0.9rem;

  ${props =>
    props.league &&
    css`
      grid-template-columns: 1.5fr 1fr 2fr 3fr 2fr;
    `}

  ${props =>
    props.balance &&
    css`
      grid-template-columns: 1.4fr 1fr 1fr;
    `}
`
const leftPadValue = '6vw'

export const TableHeaderCell = styled.div`
  background: #000;
  color: white;
  font-weight: 800;
  height: 6dvh;
  min-height: 40px;
  display: grid;
  align-items: center;

  ${props =>
    props.topLeft &&
    css`
      border-top-left-radius: var(--box-radius);
    `}

  ${props =>
    props.topRight &&
    css`
      border-top-right-radius: var(--box-radius);
    `}

  ${props =>
    props.leftAlign &&
    css`
      text-align: left;
    `}

    ${props =>
    props.padLeft &&
    css`
      padding-left: ${leftPadValue};
    `}
`

export const TableCell = styled.div`
  height: 6dvh;
  min-height: 40px;
  display: grid;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  font-weight: 600;

  ${props =>
    props.leftAlign &&
    css`
      text-align: left;
    `}

  ${props =>
    props.padLeft &&
    css`
      padding-left: ${leftPadValue};
    `}

    :nth-last-child(1),:nth-last-child(2),:nth-last-child(3),:nth-last-child(4),:nth-last-child(5) {
    border-bottom: none;
  }
`
export const BalanceTableCell = styled(TableCell)`
  :nth-last-child(1),
  :nth-last-child(2),
  :nth-last-child(3) {
    border-bottom: none;
  }
  :nth-last-child(4),
  :nth-last-child(5) {
    border-bottom: 1px solid #d8d8d8;
  }
`

export const MoneyMarker = styled.div`
  position: absolute;
  width: 3px;
  opacity: 0.5;
  height: 6dvh;
  min-height: 40px;
  background: ${props =>
    props.bench && props.colorVal === 1
      ? 'red'
      : `hsl(${(100 / props.payoutPositions) * (props.payoutPositions / props.colorVal) + 40}, ${
          (50 / props.payoutPositions) * props.colorVal + 50
        }%, 60%)`};
`
