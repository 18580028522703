import * as React from 'react'
const SvgFilter = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={23}
    viewBox='0 0 32 23'
    fill='none'
    {...props}
  >
    <path
      fill='#000'
      stroke='#000'
      d='M1.836 6.863A.84.84 0 0 1 1 6.021a.84.84 0 0 1 .836-.842H3.27a5.02 5.02 0 0 1 1.39-2.705 4.95 4.95 0 0 1 7.046-.003c.743.813 1.204 1.602 1.39 2.708h17.068A.84.84 0 0 1 31 6.02a.84.84 0 0 1-.836.842H13.096a5.02 5.02 0 0 1-1.39 2.705 4.946 4.946 0 0 1-7.043.003A5.02 5.02 0 0 1 3.27 6.863zm21.98 6.8a3.3 3.3 0 0 0-2.34.977c-.622.587-.97 1.51-.97 2.36 0 .888.345 1.732.968 2.361a3.3 3.3 0 0 0 4.686 0 3.34 3.34 0 0 0 .968-2.36c0-.889-.345-1.734-.968-2.362a3.3 3.3 0 0 0-2.343-.975Zm0-1.684c1.374 0 2.619.563 3.52 1.471a5.02 5.02 0 0 1 1.394 2.708h1.434A.84.84 0 0 1 31 17a.84.84 0 0 1-.836.843H28.73a5.02 5.02 0 0 1-1.39 2.704 4.95 4.95 0 0 1-7.046.003 5.04 5.04 0 0 1-1.39-2.707H1.836A.84.84 0 0 1 1 17a.84.84 0 0 1 .836-.842h17.068a5 5 0 0 1 1.344-2.658c.887-.97 2.27-1.52 3.569-1.52ZM10.526 3.661a3.3 3.3 0 0 0-2.342-.977c-.915 0-1.744.373-2.343.976a3.35 3.35 0 0 0-.968 2.36c0 .924.37 1.758.968 2.362a3.3 3.3 0 0 0 2.343.975c.916 0 1.745-.373 2.344-.975a3.35 3.35 0 0 0 .967-2.361c0-.85-.348-1.773-.97-2.36Z'
    />
  </svg>
)
export default SvgFilter
