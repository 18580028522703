import React from 'react'
import styled from 'styled-components'
import { PlayerImage } from 'components/patterns/PlayerImage'
import { IconTransferRg } from 'icons'

export default function Transfer({
  outImage,
  inImage,
  firstNameOut,
  lastNameOut,
  firstNameIn,
  lastNameIn,
  pandl,
  inMil,
  outMil,
  auctionSpend,
}) {
  const pandlNum = Number(pandl)
  const inMilNum = Number(inMil)
  const outMilNum = Number(outMil)
  const auctionSpendNum = Number(auctionSpend)

  return (
    <TransferGrid>
      <LeftImg>
        <PlayerImage width='100%' source={outImage} />
      </LeftImg>
      <Icon>
        <IconTransferRg />
      </Icon>
      <RightImg>
        <PlayerImage width='100%' source={inImage} />
      </RightImg>
      <Val>
        <span>{outMilNum.toFixed(1)}m</span>
        <span>{inMilNum.toFixed(1)}m</span>
      </Val>

      <LeftName>
        <First>{firstNameOut}</First>
        <Last>{lastNameOut}</Last>
      </LeftName>
      <Pnl>
        {auctionSpendNum > 0 && (
          <AuctionSpend>Overage: +{auctionSpendNum.toFixed(1)}m</AuctionSpend>
        )}
        <span>
          {pandlNum < 0 ? 'Loss' : pandlNum > 0 ? 'Profit' : 'Even'}:{' '}
          {(pandlNum > 0 ? '+' : '') + pandlNum.toFixed(1)}m
        </span>
      </Pnl>
      <RightName>
        <First>{firstNameIn}</First>
        <Last>{lastNameIn}</Last>
      </RightName>
    </TransferGrid>
  )
}

const TransferGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    'leftImg icon rightImg'
    'leftImg val rightImg'
    'leftName pnl rightName';
`
const LeftImg = styled.div`
  grid-area: leftImg;
`
const Icon = styled.div`
  grid-area: icon;
`
const RightImg = styled.div`
  grid-area: rightImg;
`
const Val = styled.div`
  grid-area: val;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.6rem;
  font-weight: 700;
`

const LeftName = styled.div`
  grid-area: leftName;
  text-align: center;
`
const RightName = styled(LeftName)`
  grid-area: rightName;
`
const Pnl = styled.div`
  grid-area: pnl;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const First = styled.p`
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1;
`
const Last = styled.p`
  font-size: 0.9rem;
  font-weight: 700;
`
const AuctionSpend = styled.span`
  font-weight: 700;
  background: #f6f6f6;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
`
