import React from 'react'
import styled from 'styled-components'
import { media } from 'styles/mediaQueries'
import { Button } from 'components/buttons/Button'

export default function FilterLayout({ handleResetFilter, handleApplyFilter, children }) {
  return (
    <FilterContainer>
      <div>
        <FilterHeader>
          <h2>Filter</h2>
          <FilterResetButton onClick={handleResetFilter}>reset</FilterResetButton>
        </FilterHeader>
        <FilterInputContainer>{children}</FilterInputContainer>
      </div>
      <Button primary onClick={handleApplyFilter} style={{ alignSelf: 'end' }}>
        Apply filters
      </Button>
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`
const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`

const FilterInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  overflow-x: visible;
  gap: 2rem;
  ${media.hlg`
    gap: 2rem;
    `}
  ${media.hmd`
      gap: 1.5rem;
      `}
  ${media.hsm`
    gap: 0.5rem;
    `}
`

const FilterResetButton = styled.button`
  border: none;
  border-radius: 10px;
  background: transparent;
  font-size: 0.9rem;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0.5rem;
  height: fit-content;
`
