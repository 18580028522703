import React from 'react'
import { ProfileContainer, Avatar, Name, StarsContainer, Plus } from './DashProfile.styled'
import { IconStar } from 'icons'
import { useAuth } from 'contexts/AuthContext'

export default function ProfileComponent({ userName, starCount }) {
  const { currentUser } = useAuth()
  const stars = Array.from({ length: starCount }, (_, index) => <IconStar key={index} />)
  const fiveStars = stars.slice(0, 5)
  const showStars = stars.length > 0
  const moreThanFiveStars = stars.length > 5

  return (
    <ProfileContainer>
      <Avatar
        src={currentUser.photoURL || 'img/profile/default-profile-img.png'}
        alt={`${userName}'s avatar`}
      />
      <Name>{userName}</Name>
      {showStars && (
        <StarsContainer>
          {moreThanFiveStars ? (
            <>
              {fiveStars} <Plus>+{stars.length - 5}</Plus>
            </>
          ) : (
            stars
          )}
        </StarsContainer>
      )}
    </ProfileContainer>
  )
}
