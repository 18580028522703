import React from 'react'
import styled from 'styled-components'

export default function SuccessAnimation({ message }) {
  return (
    <Container>
      <AnimatedTick>
        <svg
          className='ft-green-tick'
          xmlns='http://www.w3.org/2000/svg'
          height='50'
          width='50'
          viewBox='0 0 48 48'
          aria-hidden='true'
        >
          <circle className='circle' fill='#5bb543' cx='24' cy='24' r='22' />
          <path
            className='tick'
            fill='none'
            stroke='#FFF'
            strokeWidth='6'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='M14 27l5.917 4.917L34 17'
          />
        </svg>
      </AnimatedTick>
      <Message>{message}</Message>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`
const Message = styled.div`
  font-size: 0.9rem;
  font-weight: 900;
  color: green;
`

const AnimatedTick = styled.div`
  @supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
    .tick {
      stroke-opacity: 0;
      stroke-dasharray: 29px;
      stroke-dashoffset: 29px;
      animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
      animation-delay: 0.6s;
    }

    .circle {
      fill-opacity: 0;
      stroke: #219a00;
      stroke-width: 16px;
      transform-origin: center;
      transform: scale(0);
      animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
    }
  }

  @keyframes grow {
    60% {
      transform: scale(0.8);
      stroke-width: 4px;
      fill-opacity: 0;
    }
    100% {
      transform: scale(0.9);
      stroke-width: 8px;
      fill-opacity: 1;
      fill: #219a00;
    }
  }

  @keyframes draw {
    0%,
    100% {
      stroke-opacity: 1;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`
