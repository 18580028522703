import * as React from 'react'
const SvgNavMomWinners = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21} height={21} fill='none' {...props}>
    <path
      fill='currentColor'
      d='M17.885 1.615h-2.423V.808a.808.808 0 0 0-1.616 0v.807H7.385V.808a.808.808 0 0 0-1.616 0v.807H3.346A2.426 2.426 0 0 0 .923 4.038v14.539A2.426 2.426 0 0 0 3.346 21h14.539a2.426 2.426 0 0 0 2.423-2.423V4.038a2.426 2.426 0 0 0-2.423-2.423M3.346 3.231H5.77v.807a.808.808 0 0 0 1.616 0v-.807h6.461v.807a.808.808 0 0 0 1.616 0v-.807h2.423a.81.81 0 0 1 .807.807v2.424H2.538V4.038a.81.81 0 0 1 .808-.807m14.539 16.154H3.346a.81.81 0 0 1-.808-.808v-10.5h16.154v10.5a.81.81 0 0 1-.807.808'
    />
    <path
      fill='currentColor'
      d='M7.385 11.712c0 1.615-2.423 1.615-2.423 0 0-1.616 2.423-1.616 2.423 0M7.385 15.75c0 1.615-2.423 1.615-2.423 0s2.423-1.615 2.423 0M11.827 15.75c0 1.615-2.423 1.615-2.423 0s2.423-1.615 2.423 0M11.827 11.712c0 1.615-2.423 1.615-2.423 0 0-1.616 2.423-1.616 2.423 0M16.27 11.712c0 1.615-2.424 1.615-2.424 0 0-1.616 2.423-1.616 2.423 0'
    />
  </svg>
)
export default SvgNavMomWinners
