import React from 'react'
import styled from 'styled-components'
import DisplayBalance from './DisplayBalance'
import CancelTransfer from './CancelTransfer'
import { useOutletContext } from 'react-router-dom'
import { AlertError } from 'components/design-elements/Notifications'
import { SpinningCircle } from 'components/loader/Spinners'
import EmptyState, { emptyStateData } from 'views/utility/EmptyState'

export default function MyTransfers() {
  const {
    transferData: pendingEntries,
    transferLoading,
    dashData,
    dashLoading,
    transferError,
    dashError,
    userName,
    transferRefetch: refetch,
  } = useOutletContext()
  const pendingTransfers = pendingEntries
    ? [...pendingEntries]
        .filter(name => name.manager === userName)
        .reverse()
        .map(transfer => (
          <CancelTransfer
            key={transfer.outImage + transfer.inImaged}
            outImage={transfer.outImage}
            inImage={transfer.inImage}
            firstNameOut={transfer.fnOut}
            lastNameOut={transfer.lnOut}
            firstNameIn={transfer.fnIn}
            lastNameIn={transfer.lnIn}
            pandl={transfer.pandl}
            inMil={transfer.buyValue}
            outMil={transfer.saleValue}
            refetch={refetch}
          />
        ))
    : false

  const hasNoTransfers = pendingTransfers.length === 0

  return (
    <>
      {transferLoading || dashLoading ? (
        <SpinningCircle />
      ) : transferError || dashError ? (
        <AlertError>Error loading transfers...</AlertError>
      ) : (
        <>
          <DisplayBalance dashData={dashData} userName={userName} />
          {hasNoTransfers ? (
            <Container>
              <EmptyState
                image={emptyStateData.noPendingTransfers.image}
                alt={emptyStateData.noPendingTransfers.alt}
                text={emptyStateData.noPendingTransfers.text}
              />
            </Container>
          ) : (
            <>
              <PendingTransfersHeader>Pending transfers</PendingTransfersHeader>
              {pendingTransfers}
            </>
          )}
        </>
      )}
    </>
  )
}

const Container = styled.div`
  height: 40vh;
`
const PendingTransfersHeader = styled.h2`
  margin: 1.5rem 0 1rem;
`
