import React from 'react'
import { InfoLarge, Label } from './DashProfile.styled'

export default function CashTransfersComponent({ icon, label, value }) {
  return (
    <>
      {icon}
      <InfoLarge>{value}</InfoLarge>
      <Label>{label}</Label>
    </>
  )
}
