import moment from 'moment-timezone'

// gets the current Lon, NY and UTC times

export const getCurrentDateTime = () => {
  //Full ISO date and time
  const currentDateTimeInLondon = moment().tz('Europe/London').format()
  const currentDateTimeInNewYork = moment().tz('America/New_York').format()

  //Just the date formatted as 'Friday, 11 Aug'
  const currentDateInLondon = moment().tz('Europe/London').format('ddd, DD MMM')
  const currentDateInNewYork = moment().tz('America/New_York').format('ddd, DD MMM')

  //Just the date formatted as '11:00'
  const currentTimeInLondon = currentDateTimeInLondon.slice(11, 16)
  const currentTimeInNewYork = currentDateTimeInNewYork.slice(11, 16)

  const currentTimeUTC = moment().utc().toISOString()

  return {
    currentDateTimeInLondon,
    currentDateTimeInNewYork,
    currentDateInLondon,
    currentDateInNewYork,
    currentTimeInLondon,
    currentTimeInNewYork,
    currentTimeUTC,
  }
}

// takes an ISO 8601 date string of 20 characters and converts it to
// London and New York times and dates using moment

export const getConvertedDateTime = time => {
  let timeLondon
  let timeNY
  const dateTimeLondon = moment(time).tz('Europe/London').format()
  const dateTimeNY = moment(time).tz('America/New_York').format()
  const dateLondon = moment(time).tz('Europe/London').format('ddd, DD MMM')
  const dateNY = moment(time).tz('America/New_York').format('ddd, DD MMM')

  if (time.length >= 20) {
    timeLondon = dateTimeLondon.slice(11, 16)
    timeNY = dateTimeNY.slice(11, 16)
  } else {
    timeLondon = '00:00'
    timeNY = '00:00'
  }
  return {
    timeLondon,
    timeNY,
    dateTimeLondon,
    dateTimeNY,
    dateLondon,
    dateNY,
  }
}

// subtracts hours from an ISO string and returns the UTC string

export const subtractHours = (isoTimeString, hours = 0) => {
  const momentObject = moment(isoTimeString)
  const newIsoDateString = momentObject.subtract(hours, 'hours').toISOString()
  return newIsoDateString
}
