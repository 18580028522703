import * as React from 'react'
const SvgIconShortlist = ({ scale = 1 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={95 * scale}
    height={66 * scale}
    viewBox='0 0 95 66'
    fill='none'
  >
    <g stroke='#000'>
      <path strokeLinecap='round' strokeWidth={13} d='M33 58h55M33 33h55M33 8h55' />
      <circle cx={8} cy={8} r={7.5} fill='#000' />
      <circle cx={8} cy={33} r={7.5} fill='#000' />
      <circle cx={8} cy={58} r={7.5} fill='#000' />
    </g>
  </svg>
)
export default SvgIconShortlist
