import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IconGear } from 'icons'

export default function DashHeader() {
  return (
    <>
      <Container>
        <LandingLogo />
        <Link to='/profile'>
          <IconGear scale='0.8' />
        </Link>
      </Container>
    </>
  )
}

const LandingLogo = styled.img.attrs({
  src: 'img/assets/ff-logo.svg',
  alt: 'logo',
})`
  height: 35px;
`
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
