import { useGwData } from 'hooks/useApiCall'
import { getCurrentDateTime, subtractHours } from 'hooks/useTimeConvert'
import { teamChangeCutOff } from 'settings/config'
import useVersionFlag from 'hooks/useVersionFlag'

export default function useCanUpdateTeam() {
  const { gwData } = useGwData()
  const { currentTimeUTC } = getCurrentDateTime()
  const { isUK } = useVersionFlag()

  const localCutOff = isUK ? teamChangeCutOff.lon.hours : teamChangeCutOff.ny.hours

  const gwStartTimeUTC = gwData ? gwData[0].gwStartTime : '1999-01-01T00:00:00'
  const gwGameDayUTC = gwData ? gwData[0].currentGwGameDayISO : '1999-01-01T00:00:00+01:00'
  const manualButtonToggle = gwData ? gwData[0].canSubmitSubs : 'OFF'

  const subsLockTimeUTC = subtractHours(gwGameDayUTC, localCutOff)

  const canUpdateTeam =
    (gwStartTimeUTC <= currentTimeUTC &&
      subsLockTimeUTC >= currentTimeUTC &&
      manualButtonToggle === 'DEFAULT') ||
    manualButtonToggle === 'ON'

  return { canUpdateTeam, gwGameDayUTC }
}
