import * as React from 'react'
const SvgEyeOpen = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth={2}
    className='eyeOpen_svg__feather eyeOpen_svg__feather-eye'
    {...props}
  >
    <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8' />
    <circle cx={12} cy={12} r={3} />
  </svg>
)
export default SvgEyeOpen
