import React from 'react'
import { IconChevronUp, IconChevronDown } from 'icons'

const up = <IconChevronUp scale='0.6' stroke='var(--color-green)' />
const down = <IconChevronDown scale='0.6' stroke='var(--color-red)' />

export default function Chevron({ score, total, chevron }) {
  let chevy
  if (score === total) {
    chevy = null
  } else if (chevron === 'up') {
    chevy = up
  } else if (chevron === 'down') {
    chevy = down
  } else {
    chevy = null
  }

  return chevy
}
