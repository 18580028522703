import styled from 'styled-components'

export const LargeFont = styled.p`
  font-size: 10vmin;
  font-weight: 400;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: -2px;
  margin-inline-end: 0px;
`
export const LittleFont = styled(LargeFont)`
  font-size: 0.8rem;
  font-weight: 400;
  margin-inline-start: 0px;
`

export const LoaderContainer = styled.div`
  text-align: center;
`
