import styled, { css } from 'styled-components'
import {
  SvgError,
  SvgInfo,
  SvgWarning,
  SvgSuccess,
  SvgLock,
} from 'styles/SvgIcon'
import { SpinningCircle } from 'components/loader/Spinners'

// Error and success alerts
const alertColors = {
  error: { bg: '#e53e3e', fg: '#fed7d7' },
  warning: { bg: '#d29e20', fg: '#feebc8' },
  info: { bg: '#3182ce', fg: '#bee3f8' },
  success: { bg: '#38a169', fg: '#c6f6d5' },
  winning: { bg: '#497e3f', fg: '#b5e8a4' },
  locked: { bg: '#a8a8a8', fg: '#e7e7e7' },
}

const Notification = styled.div`
  width: 100%;
  display: flex;
  font-weight: 600;
  font-size: ${({ size }) => size || '0.8rem'};
  -webkit-box-align: center;
  border-radius: var(--box-radius);
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #ccc;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  ${props =>
    props.error &&
    css`
      background: ${alertColors.error.fg};
      border: 1px solid ${alertColors.error.bg};
    `}
  ${props =>
    props.warning &&
    css`
      background: ${alertColors.warning.fg};
      border: 1px solid ${alertColors.warning.bg};
    `}
    ${props =>
    props.info &&
    css`
      background: ${alertColors.info.fg};
      border: 1px solid ${alertColors.info.bg};
    `}
      ${props =>
    props.success &&
    css`
      background: ${alertColors.success.fg};
      border: 1px solid ${alertColors.success.bg};
    `}
      ${props =>
    props.locked &&
    css`
      background: ${alertColors.locked.fg};
      border: 1px solid ${alertColors.locked.bg};
    `}
      ${props =>
    props.winning &&
    css`
      background: ${alertColors.winning.fg};
      border: 1px solid ${alertColors.winning.bg};
    `}
      ${props =>
    props.center &&
    css`
      justify-content: center;
      text-align: center;
    `}
      ${props =>
    props.rightPadd &&
    css`
      padding-right: 35px;
    `}
      ${props =>
    props.halfRoundNoBorder &&
    css`
      border: none;
      border-radius: 10px 10px 0px 0px;
    `}
`
export const NotificationIcon = styled.span`
  color: #aaa;
  flex-shrink: 0;
  margin-inline-end: 0.75rem;
  width: 1.25rem;
  height: 1.5rem;
  display: inherit;
  ${props =>
    props.error &&
    css`
      path {
        fill: ${alertColors.error.bg};
      }
    `}
  ${props =>
    props.warning &&
    css`
      path {
        fill: ${alertColors.warning.bg};
      }
    `}
    ${props =>
    props.info &&
    css`
      path {
        fill: ${alertColors.info.bg};
      }
    `}
    ${props =>
    props.success &&
    css`
      path {
        fill: ${alertColors.success.bg};
      }
    `}
    ${props =>
    props.locked &&
    css`
      path {
        fill: ${alertColors.locked.bg};
      }
    `}
    ${props =>
    props.winning &&
    css`
      path {
        fill: ${alertColors.winning.bg};
      }
    `}
`

export const AlertError = ({ children }) => (
  <>
    <Notification error>
      <NotificationIcon error>
        <SvgError />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertWarning = ({ children }) => (
  <>
    <Notification warning>
      <NotificationIcon warning>
        <SvgWarning />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertInfo = ({ children }) => (
  <>
    <Notification info>
      <NotificationIcon info>
        <SvgInfo />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertSuccess = ({ children }) => (
  <>
    <Notification success>
      <NotificationIcon success>
        <SvgSuccess />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertLocked = ({ children }) => (
  <>
    <Notification locked center rightPadd>
      <NotificationIcon locked>
        <SvgLock />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertPlayerSelect = ({ children }) => (
  <>
    <Notification warning center>
      {children}
    </Notification>
  </>
)

export const ErrorPlayerSelect = ({ children }) => (
  <>
    <Notification error center>
      {children}
    </Notification>
  </>
)

export const AlertAuctionSuccess = ({ children, size }) => (
  <>
    <Notification size={size} winning center halfRoundNoBorder>
      <NotificationIcon winning>
        <SvgSuccess />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)

export const AlertAuctionWarning = ({ children }) => (
  <>
    <Notification warning center halfRoundNoBorder>
      <NotificationIcon warning>
        <SvgWarning />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)
export const AlertAuctionLoading = () => (
  <>
    <Notification warning center halfRoundNoBorder>
      <SpinningCircle color='#00000080' />
    </Notification>
  </>
)

export const AlertAuctionError = ({ children }) => (
  <>
    <Notification error center halfRoundNoBorder>
      <NotificationIcon error>
        <SvgError />
      </NotificationIcon>
      {children}
    </Notification>
  </>
)
