import * as React from 'react'
const SvgIconChevronDown = ({ scale = 1, stroke = '#000' }) => {
  const w = 19 * scale
  const h = 11 * scale
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={w} height={h} fill='none' viewBox='0 0 19 11'>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='M17 2 9.5 9 2 2'
      />
    </svg>
  )
}
export default SvgIconChevronDown
