import * as React from 'react'
const SvgEmptyStateNothingToSee = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={221} height={205} fill='none' {...props}>
    <path
      fill='#939BA5'
      d='m98.25 32.95 8.125-.95.593 74.968a4 4 0 0 1-4 4.032H95V36.754zM93.5 126.5v-2h8.875a4 4 0 0 1 4 4v66.952L100 203l-5-5.032-1-69.468zM54.5 70l5.5-2.5V125h-8V75.5zM153.86 90.534 160 90v39a4 4 0 0 1-4 4h-6v-4.607l1.404-35.722z'
    />
    <path
      fill='#000'
      d='M161.072 83.364c-8.426 0-15.378 6.952-15.378 15.378v25.911h-14.746v-80.05c0-11.164-9.058-20.223-20.223-20.223S90.502 33.44 90.502 44.603v57.72H75.756v-25.91c0-8.427-6.952-15.378-15.378-15.378S45 67.986 45 76.413V117.7c0 8.427 6.952 15.378 15.378 15.378h30.124v62.987H69.436c-2.528 0-4.424 2.106-4.424 4.423 0 2.528 2.107 4.424 4.424 4.424h82.367c2.528 0 4.424-2.106 4.424-4.424 0-2.527-2.107-4.423-4.424-4.423h-21.066v-40.657h30.124c8.426 0 15.378-6.952 15.378-15.378V98.742c.211-8.426-6.53-15.378-15.167-15.378m6.53 56.667c0 3.581-2.949 6.32-6.319 6.32h-34.759c-2.528 0-4.424 2.106-4.424 4.423v45.292H99.77v-67.411c0-2.527-2.106-4.423-4.423-4.423H60.799c-3.58 0-6.32-2.95-6.32-6.32v-41.5c0-3.58 2.95-6.32 6.32-6.32s6.32 2.95 6.32 6.32v30.335c0 2.528 2.106 4.424 4.424 4.424h23.593c2.528 0 4.424-2.107 4.424-4.424V44.603c0-6.109 5.056-11.164 11.165-11.164s11.165 5.055 11.165 11.164v84.474c0 2.528 2.106 4.424 4.423 4.424h23.594c2.528 0 4.424-2.107 4.424-4.424V98.742c0-3.581 2.949-6.32 6.32-6.32 3.581 0 6.319 2.95 6.319 6.32l.632 39.758zM183.191 5c-17.485 0-31.809 14.325-31.809 31.81s14.324 31.808 31.809 31.808S215 54.294 215 36.81 200.676 5 183.191 5m0 54.56c-12.639 0-22.751-10.322-22.751-22.75 0-12.64 10.322-22.752 22.751-22.752 12.639 0 22.751 10.322 22.751 22.751S195.62 59.56 183.191 59.56'
    />
  </svg>
)
export default SvgEmptyStateNothingToSee
