import React, { useEffect, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import useSetFBdata from 'hooks/useSetFBdata'
import Loading from 'components/loader/Loading'
import BottomNav from 'components/navigation/bottom-nav/BottomNav'
import Routes from 'routes'

const renderLoader = () => <Loading />

export default function App() {
  const { registerVersion, currentUser } = useSetFBdata()

  useEffect(() => {
    registerVersion()
  }, [currentUser])

  return (
    <Suspense fallback={renderLoader()}>
      <Helmet>
        <meta name='theme-color' content='#ffffff' />
      </Helmet>
      <Routes />
      {currentUser && <BottomNav />}
    </Suspense>
  )
}
