import * as React from 'react'
const SvgEmptyStateNoPendingTransfers = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={211} height={186} fill='none' {...props}>
    <ellipse
      cx={105.5}
      cy={177}
      fill='url(#emptyState_noPendingTransfers_svg__a)'
      rx={105.5}
      ry={8.961}
    />
    <path
      fill='#939BA5'
      d='M50 59h104v15H50l-2-6.964zM151 99.5l9.5-1.5H173v31h-22l-3-7.75v-15.5z'
    />
    <path
      fill='#000'
      d='M160.092 106.904c-3.724 0-6.77 3.047-6.77 6.77 0 3.724 3.046 6.77 6.77 6.77s6.77-3.046 6.77-6.77-3.046-6.77-6.77-6.77m0 9.027a2.263 2.263 0 0 1-2.257-2.257 2.263 2.263 0 0 1 2.257-2.256 2.263 2.263 0 0 1 2.257 2.256 2.263 2.263 0 0 1-2.257 2.257M61.813 68.54h80.902a2.263 2.263 0 0 0 2.257-2.257 2.263 2.263 0 0 0-2.257-2.256H61.813a2.263 2.263 0 0 0-2.257 2.257 2.263 2.263 0 0 0 2.257 2.256'
    />
    <path
      fill='#000'
      d='M169.119 95.62V79.825c0-3.724-3.047-6.77-6.77-6.77h-4.514V61.77c0-3.723-3.046-6.77-6.77-6.77H56.284C50.055 55 45 60.055 45 66.284v78.984c0 6.229 5.055 11.284 11.283 11.284H162.35c3.723 0 6.77-3.047 6.77-6.77v-18.054c3.723 0 6.77-3.047 6.77-6.77v-22.567c0-3.724-3.047-6.77-6.77-6.77M56.284 59.514h94.781a2.264 2.264 0 0 1 2.257 2.257v11.284H56.284c-3.724 0-6.77-3.047-6.77-6.77 0-3.724 3.046-6.77 6.77-6.77m108.321 90.269a2.26 2.26 0 0 1-2.256 2.256H56.284c-3.724 0-6.77-3.046-6.77-6.77V75.243c1.895 1.421 4.22 2.324 6.77 2.324h106.065a2.263 2.263 0 0 1 2.256 2.257V95.62h-9.026c-6.229 0-11.284 5.055-11.284 11.283v13.54c0 6.229 5.055 11.284 11.284 11.284h9.026zm6.77-24.824a2.263 2.263 0 0 1-2.256 2.257h-13.54c-3.724 0-6.771-3.047-6.771-6.771v-13.54c0-3.723 3.047-6.77 6.771-6.77h13.54a2.263 2.263 0 0 1 2.256 2.257z'
    />
    <path
      stroke='#000'
      strokeDasharray='4 4'
      strokeLinecap='round'
      strokeWidth={2}
      d='M159.5 21v0a30.64 30.64 0 0 1-19.855 11.9L132.5 34v0c-8.228 1.388-12.847-9.144-6.251-14.256l.342-.265c3.911-3.031 9.601-1.897 12.05 2.404l3.347 5.876a14 14 0 0 1-2.029 16.586l-5.958 6.253'
    />
    <ellipse
      cx={165.892}
      cy={14.776}
      fill='#000'
      rx={5}
      ry={2.5}
      transform='rotate(-46.882 165.892 14.776)'
    />
    <ellipse
      cx={168.208}
      cy={16.945}
      fill='#000'
      rx={2.776}
      ry={1.586}
      transform='rotate(43.118 168.208 16.945)'
    />
    <ellipse
      cx={163.576}
      cy={12.608}
      fill='#000'
      rx={2.776}
      ry={1.586}
      transform='rotate(43.118 163.576 12.608)'
    />
    <defs>
      <radialGradient
        id='emptyState_noPendingTransfers_svg__a'
        cx={0}
        cy={0}
        r={1}
        gradientTransform='matrix(105.5 0 0 8.96139 105.5 177)'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#D9D9D9' />
        <stop offset={1} stopColor='#EEE' />
      </radialGradient>
    </defs>
  </svg>
)
export default SvgEmptyStateNoPendingTransfers
