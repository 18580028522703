import React from 'react'
import { PointsContainer, Container, Info, Label, Up, Down } from './DashProfile.styled'

export default function PointsComponent({ userDashData }) {
  const originalPos = userDashData[0].pos
  const lastWeekPos = Number(userDashData[0].lwpos.replace('T', ''))
  const editedPos = originalPos.replace('T', '')

  const pos = userDashData.length === 0 ? '' : Number(editedPos)
  const total = userDashData.length === 0 ? '' : userDashData[0].total

  const leagueMove = pos < lastWeekPos ? <Up /> : pos > lastWeekPos ? <Down /> : null

  let ord
  if (pos === 1) {
    ord = 'st'
  } else if (pos === 2) {
    ord = 'nd'
  } else if (pos === 3) {
    ord = 'rd'
  } else {
    ord = 'th'
  }

  return (
    <PointsContainer>
      <Container>
        <Info>
          {originalPos.includes('T') ? 'T' : null}
          {pos}
          {ord}
          {leagueMove}
        </Info>
        <Label>Position</Label>
      </Container>
      <Container>
        <Info>{total}</Info>
        <Label>Points</Label>
      </Container>
    </PointsContainer>
  )
}
