import * as React from 'react'
const SvgIconShortlistRemove = ({ scale = 1 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={68 * scale}
    height={87 * scale}
    viewBox='0 0 68 87'
    fill='none'
  >
    <path fill='#FFDBDB' d='M4.925 4.952H50.58L63.834 18.32v62.877H4.924z' />
    <path
      fill='#FF3B30'
      d='M9.987.114C4.98.114.851 4.277.851 9.328V76.9c0 5.051 4.128 9.214 9.136 9.214h48.728c5.009 0 9.136-4.163 9.136-9.214V18.543c0-.815-.321-1.596-.892-2.172L51.732 1.014a3.03 3.03 0 0 0-2.154-.9zm0 6.143h33.5v9.214c0 5.052 4.128 9.215 9.137 9.215h9.136V76.9c0 1.755-1.305 3.071-3.045 3.071H9.987c-1.74 0-3.045-1.316-3.045-3.071V9.328c0-1.754 1.306-3.071 3.045-3.071m39.591 1.272L60.5 18.543h-7.875c-1.74 0-3.046-1.317-3.046-3.072z'
    />
    <path
      stroke='#FF3B30'
      strokeLinecap='round'
      strokeWidth={7}
      d='m23.584 60.116 21.534-21.718M45.119 60.116 23.584 38.397'
    />
  </svg>
)
export default SvgIconShortlistRemove
