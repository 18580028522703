// Helper function to create a player object
function createPlayer(data, prefix) {
  return {
    id: data[`${prefix}img`],
    name: data[prefix],
    lastName: data[`${prefix}ln`],
    imgId: data[`${prefix}img`],
    pos: data[`${prefix}pos`],
    nextGame: data[`${prefix}ng`],
    bid: data[`${prefix}Bid`],
  }
}

// Helper function to filter out players with empty IDs
function filterPlayers(players) {
  return players.filter(player => player.id.length > 0)
}

// Main function to format team data
export function myTeamFormatter(userTeamData) {
  const data = userTeamData[0]

  const defendersArray = filterPlayers([
    createPlayer(data, 'def1'),
    createPlayer(data, 'def2'),
    createPlayer(data, 'def3'),
    createPlayer(data, 'def4'),
    createPlayer(data, 'def5'),
  ])

  const midfieldArray = filterPlayers([
    createPlayer(data, 'mid1'),
    createPlayer(data, 'mid2'),
    createPlayer(data, 'mid3'),
    createPlayer(data, 'mid4'),
    createPlayer(data, 'mid5'),
  ])

  const forwardArray = filterPlayers([
    createPlayer(data, 'fwd1'),
    createPlayer(data, 'fwd2'),
    createPlayer(data, 'fwd3'),
  ])

  const subsArray = filterPlayers([
    createPlayer(data, 'sub1'),
    createPlayer(data, 'sub2'),
    createPlayer(data, 'sub3'),
  ])

  return { defendersArray, midfieldArray, forwardArray, subsArray }
}
