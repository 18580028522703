import React from 'react'
import { AlertInfo } from 'components/design-elements/Notifications'
import styled from 'styled-components'
import useVersionFlag from 'hooks/useVersionFlag'

// This component is used to display a warning to all users if any particular manager
// has more than 3 players in their team from one club or they have gone into their overdraft

export default function DashSpendWarning({ dashData }) {
  const { isUK } = useVersionFlag()
  const currency = isUK ? '£' : '$'

  const maxPlayerWarning = dashData
    .filter(entry => entry.overClubMax)
    .map(entry => (
      <AlertInfo key={entry.name}>
        Public service announcement:
        <br />
        {entry.name} has more than 3 {entry.overClubMax} players in his team at present. He will not
        accrue any points in the league until this is rectified.
      </AlertInfo>
    ))

  const overdraftWarning = dashData
    .filter(entry => entry.inOverdraft)
    .map(entry => (
      <AlertInfo key={entry.name}>
        Public service announcement:
        <br />
        {entry.name} is currently {currency}
        {Math.abs(Number(entry.inOverdraft))}m in his overdraft. He will not accrue any points in
        the league until this is rectified.
      </AlertInfo>
    ))

  const warningTest = maxPlayerWarning.length !== 0 || overdraftWarning.length !== 0
  const playerWarningTest = maxPlayerWarning.length !== 0
  const overdraftTest = overdraftWarning.length !== 0

  return (
    <>
      {warningTest && (
        <AlertContainer>
          {playerWarningTest && maxPlayerWarning}
          {overdraftTest && overdraftWarning}
        </AlertContainer>
      )}
    </>
  )
}

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;
`
