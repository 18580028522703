import * as React from "react";
const SvgIconTransferRg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#DD6969"
      fillRule="evenodd"
      d="M18.428 5.947H1.982a1.983 1.983 0 0 0 0 3.964h16.446l-2.87 2.46a1.985 1.985 0 0 0-.214 2.795c.712.83 1.964.927 2.796.215l6.938-5.947a1.983 1.983 0 0 0 0-3.01L18.14.476a1.985 1.985 0 0 0-2.796.215 1.985 1.985 0 0 0 .215 2.796z"
      clipRule="evenodd"
    />
    <path
      fill="#77B533"
      fillRule="evenodd"
      d="M7.341 25.911h16.446a1.983 1.983 0 0 0 0-3.964H7.341l2.87-2.46c.83-.712.926-1.964.215-2.795a1.985 1.985 0 0 0-2.796-.215L.692 22.423a1.983 1.983 0 0 0 0 3.011l6.938 5.947a1.985 1.985 0 0 0 2.796-.215 1.985 1.985 0 0 0-.215-2.796z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconTransferRg;
