import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { AlertError } from 'components/design-elements/Notifications'

export default function CustomErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      fallback={
        <AlertError>
          Failed to load. Try refreshing the page or returning to the home screen.
        </AlertError>
      }
    >
      {children}
    </ErrorBoundary>
  )
}
