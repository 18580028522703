import React from 'react'
import styled from 'styled-components'

export default function DashBackground() {
  return (
    <Container>
      <Elipse1 />
      <Elipse2 />
      <Background />
      <FadeLayer />
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  position: absolute;
  overflow-x: hidden;
  inset: 0;
  z-index: -3;
`

const Background = styled.div`
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 600px;
  inset: 0;
  background: linear-gradient(125.64deg, #e35e5e 10.23%, #6b6b83 54.47%, #3b8d99 96.9%);
`

const Elipse1 = styled.div`
  position: absolute;
  width: 374px;
  height: 374px;
  left: -136px;
  top: -90px;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 224, 62, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`
const Elipse2 = styled.div`
  position: absolute;
  width: 374px;
  height: 374px;
  left: 83px;
  top: -71px;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(62, 162, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const FadeLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 550px;
  left: 0px;
  top: 200px;
  z-index: -1;
  background: linear-gradient(0deg, #ececec 40.94%, rgba(236, 236, 236, 0) 84.54%);
`
