import * as React from 'react'
const SvgNavSummer = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={25} height={25} fill='none' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12.5 8.733a3.767 3.767 0 1 0 0 7.534 3.767 3.767 0 0 0 0-7.534M7.02 12.5a5.48 5.48 0 1 1 10.96 0 5.48 5.48 0 0 1-10.96 0M12.5 0c.473 0 .856.383.856.856v3.425a.856.856 0 0 1-1.712 0V.856c0-.473.383-.856.856-.856M12.5 19.863c.473 0 .856.383.856.856v3.425a.856.856 0 0 1-1.712 0v-3.425c0-.473.383-.856.856-.856M25 12.5a.856.856 0 0 1-.856.856h-3.425a.856.856 0 0 1 0-1.712h3.425c.473 0 .856.383.856.856M5.137 12.5a.856.856 0 0 1-.856.856H.856a.856.856 0 0 1 0-1.712h3.425c.473 0 .856.383.856.856M21.54 3.46a.856.856 0 0 1 0 1.211l-2.422 2.422a.856.856 0 0 1-1.211-1.21l2.422-2.422a.856.856 0 0 1 1.21 0M7.093 17.907a.856.856 0 0 1 0 1.21L4.67 21.54a.856.856 0 1 1-1.21-1.21l2.421-2.422a.856.856 0 0 1 1.211 0M21.54 21.54a.856.856 0 0 1-1.211 0l-2.422-2.422a.856.856 0 1 1 1.21-1.211l2.422 2.422a.856.856 0 0 1 0 1.21M7.093 7.093a.856.856 0 0 1-1.21 0L3.46 4.67a.856.856 0 0 1 1.21-1.21l2.422 2.421a.856.856 0 0 1 0 1.211'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgNavSummer
