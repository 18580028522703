// Helper function to create a player object with last name and image ID
function createPlayer(lastName, imgId) {
  return { lastName, imgId }
}

// Helper function to filter out empty entries
function filterPlayers(players) {
  return players.filter(player => player.lastName.length > 0)
}

// Main function to format team data
export function allTeamsFormatter(teamsData) {
  return teamsData.map(row => ({
    manager: row.manager,
    lastUpdated: row.lastUpdatedRead,
    keeper: [createPlayer(row.kepln, row.kepimg)],
    defenders: filterPlayers([
      createPlayer(row.def1ln, row.def1img),
      createPlayer(row.def2ln, row.def2img),
      createPlayer(row.def3ln, row.def3img),
      createPlayer(row.def4ln, row.def4img),
      createPlayer(row.def5ln, row.def5img),
    ]),
    midfielders: filterPlayers([
      createPlayer(row.mid1ln, row.mid1img),
      createPlayer(row.mid2ln, row.mid2img),
      createPlayer(row.mid3ln, row.mid3img),
      createPlayer(row.mid4ln, row.mid4img),
      createPlayer(row.mid5ln, row.mid5img),
    ]),
    forwards: filterPlayers([
      createPlayer(row.fwd1ln, row.fwd1img),
      createPlayer(row.fwd2ln, row.fwd2img),
      createPlayer(row.fwd3ln, row.fwd3img),
    ]),
    subs: filterPlayers([
      createPlayer(row.sub1ln, row.sub1img),
      createPlayer(row.sub2ln, row.sub2img),
      createPlayer(row.sub3ln, row.sub3img),
    ]),
  }))
}
