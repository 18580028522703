// Config file for all settings that can get adjusted year on year

/*================================================
Manages the timings for the UK and US auction room openings and closings
All strings are in local times
================================================*/
export const auctionRoomTimings = {
  lon: {
    firstStart: '12:00:00',
    firstEnd: '13:30:00',
    secondStart: '15:00:00',
    secondEnd: '16:00:00',
  },
  ny: {
    firstStart: '10:00:00',
    firstEnd: '11:30:00',
    secondStart: '12:00:00',
    secondEnd: '13:00:00',
  },
}

/*================================================
Sets what time the team submission cut-off displays 
in 'ManageMyTeam.js' before the first game of the game week
================================================*/
export const teamChangeCutOff = {
  lon: {
    hours: 2,
  },
  ny: {
    hours: 1.5,
  },
}
