import React from 'react'
import styled, { css, keyframes } from 'styled-components'

export default function Overlay({ open, setOpen }) {
  return <OverlayPane open={open} onClick={() => setOpen(false)} />
}

const OverlayPane = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #00000050;
  backdrop-filter: blur(3px);
  cursor: pointer;
  display: ${({ open }) => (open ? 'block' : 'none')};
  animation: ${({ open }) => (open ? css`0.5s ${fadeIn} ease-out` : css`0.5s ${fadeOut} ease-out`)};
  z-index: var(--zi-overlay);
`
const fadeIn = keyframes`
   0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
   0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: none;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`
