import React from 'react'
import styled from 'styled-components'
import { LargeFont } from './Dash.styled'
import PlayerContainer from 'components/patterns/PlayerContainer'

export default function DashScoresRow({ image, name, points, ffm }) {
  const firstName = name.substring(0, name.indexOf(' '))
  const secondName = name.substring(name.indexOf(' ') + 1)

  return (
    <Grid>
      <Player>
        <PlayerContainer
          containerWidth='18vmin'
          source={image}
          firstName={firstName}
          secondName={secondName}
          nameSize='0.7'
        />
      </Player>
      <Score>
        <LargeFont>{points}</LargeFont>
      </Score>
      <OwnedBy>
        <Owned ffm={ffm} />
      </OwnedBy>
    </Grid>
  )
}

const Owned = ({ ffm }) => {
  return (
    <>
      {ffm === 'Nobody' ? (
        <OwnedByManager>
          Free
          <br /> agent
        </OwnedByManager>
      ) : (
        <>
          <OwnedByText>Owned by</OwnedByText>
          <OwnedByManager>{ffm}</OwnedByManager>
        </>
      )}
    </>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
  grid-template-areas: 'player score ownedby';
  :last-child {
    margin-bottom: 0px;
  }
`
const Player = styled.div`
  grid-area: player;
  justify-self: left;
`
const Score = styled.div`
  grid-area: score;
  place-self: center;
`
const OwnedBy = styled.div`
  grid-area: ownedby;
  place-self: center;
  text-align: center;
`
const OwnedByText = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  white-space: nowrap;
`
const OwnedByManager = styled(OwnedByText)`
  font-size: 0.9rem;
  font-weight: 700;
`
