import React, { lazy } from 'react'
import { useRoutes, Navigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import FourOFour from 'views/utility/FourOFour'
import { LeagueSwitcher } from './views/league/League'
import Dashboard from './views/home/Dashboard'

import Teams from './views/teams/Teams'
import MyTeam from './views/teams/myTeam/MyTeam'
import AllTeams from './views/teams/allTeams/AllTeams'

import Transfers from './views/transfers/Transfers'
import TransferHistory from './views/transfers/history/TransferHistory'
import MyTransfers from './views/transfers/myTransfers/MyTransfers'
import League from './views/league/League'

const Signup = lazy(() => import('./views/logged-out/Signup'))
const Login = lazy(() => import('./views/logged-out/Login'))
const ForgotPassword = lazy(() => import('./views/logged-out/ForgotPassword'))
const ResetEmailMessage = lazy(() => import('./views/logged-out/ResetEmailMessage'))

const TheMarket = lazy(() => import('./views/market/TheMarket'))
const Auctionroom = lazy(() => import('./views/auctionroom/Auctionroom'))
const Livescores = lazy(() => import('./views/livescores/Livescores'))
const Balance = lazy(() => import('./views/balance/Balance'))
const MomWinners = lazy(() => import('./views/momWinners/MomWinners'))
const Rules = lazy(() => import('./views/rules/Rules'))
const SummerAuction = lazy(() => import('./views/summerAuction/SummerAuction'))
const SideBets = lazy(() => import('./views/sideBets/SideBets'))
const HallOfFame = lazy(() => import('./views/hallOfFame/HallOfFame'))
const XmasCup = lazy(() => import('./views/cups/xmasCup/XmasCup'))
const FFCup = lazy(() => import('./views/cups/ffCup/FFCup'))
const Profile = lazy(() => import('./views/profile/Profile'))
const Admin = lazy(() => import('./views/profile/admin/Admin'))
const DesignSystem = lazy(() => import('./views/utility/DesignSystem'))

export default function Routes() {
  const element = useRoutes([
    /* 
============================================================
Unauthenticated rotues
============================================================  
*/
    { path: 'sign-up', element: <Signup /> },
    { path: 'login', element: <Login /> },
    { path: 'forgot-password', element: <ForgotPassword /> },
    { path: 'confirm-email', element: <ResetEmailMessage /> },

    /* 
============================================================
Authenticated / Protected rotues
============================================================  
*/
    {
      path: '/',
      element: (
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      ),
    },
    {
      path: 'transfers',
      element: (
        <RequireAuth>
          <Transfers />
        </RequireAuth>
      ),
      children: [
        { index: true, element: <TransferHistory /> },
        { path: 'my-transfers', element: <MyTransfers /> },
      ],
    },
    {
      path: 'teams',
      element: (
        <RequireAuth>
          <Teams />
        </RequireAuth>
      ),
      children: [
        { index: true, element: <AllTeams /> },
        { path: 'my-team', element: <MyTeam /> },
      ],
    },
    {
      path: 'league',
      element: (
        <RequireAuth>
          <League />
        </RequireAuth>
      ),
      children: [
        { index: true, element: <LeagueSwitcher /> },
        { path: 'bench', element: <LeagueSwitcher bench /> },
      ],
    },
    {
      path: 'the-market',
      element: (
        <RequireAuth>
          <TheMarket />
        </RequireAuth>
      ),
    },
    {
      path: 'auction-room',
      element: (
        <RequireAuth>
          <Auctionroom />
        </RequireAuth>
      ),
    },
    {
      path: 'live-scores',
      element: (
        <RequireAuth>
          <Livescores />
        </RequireAuth>
      ),
    },
    {
      path: 'balance',
      element: (
        <RequireAuth>
          <Balance />
        </RequireAuth>
      ),
    },
    {
      path: 'mom-winners',
      element: (
        <RequireAuth>
          <MomWinners />
        </RequireAuth>
      ),
    },
    {
      path: 'summer-auction',
      element: (
        <RequireAuth>
          <SummerAuction />
        </RequireAuth>
      ),
    },
    {
      path: 'side-bets',
      element: (
        <RequireAuth>
          <SideBets />
        </RequireAuth>
      ),
    },
    {
      path: 'hall-of-fame',
      element: (
        <RequireAuth>
          <HallOfFame />
        </RequireAuth>
      ),
    },
    {
      path: 'christmas-cup',
      element: (
        <RequireAuth>
          <XmasCup />
        </RequireAuth>
      ),
    },
    {
      path: 'ff-cup',
      element: (
        <RequireAuth>
          <FFCup />
        </RequireAuth>
      ),
    },
    {
      path: 'rules',
      element: (
        <RequireAuth>
          <Rules />
        </RequireAuth>
      ),
    },
    {
      path: 'profile',
      element: (
        <RequireAuth>
          <Profile />
        </RequireAuth>
      ),
    },
    {
      path: 'admin',
      element: (
        <RequireAuth>
          <Admin />
        </RequireAuth>
      ),
    },
    {
      path: 'design-system',
      element: (
        <RequireAuth>
          <DesignSystem />
        </RequireAuth>
      ),
    },
    { path: '*', element: <FourOFour /> },
  ])
  return element
}

function RequireAuth({ children }) {
  const { currentUser } = useAuth()
  return currentUser ? children : <Navigate to='/login' />
}
