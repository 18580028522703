import * as React from 'react'
const SvgIconActivity = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth={2}
    className='IconActivity_svg__feather IconActivity_svg__feather-activity'
    {...props}
  >
    <path d='M22 12h-4l-3 9L9 3l-3 9H2' />
  </svg>
)
export default SvgIconActivity
