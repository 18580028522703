import styled from 'styled-components'

export const PitchContainer = styled.div`
  width: 100%;
  padding: 1rem;
`
export const Pitch = styled.img`
  width: 100%;
  height: auto;
`

export const TeamStarters = styled.div`
  position: relative;
  padding: ${props => props.padding || '1rem'};
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4fr 1.2fr;
`

export const TeamStartersContainer = styled.div`
  position: absolute;
  inset: 0;
  height: 100%;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 0.5fr 1fr 1fr 1fr 1.3fr;
  grid-template-areas:
    'keeper'
    'defenders'
    'midfielders'
    'forwards'
    'subs';
`

export const TeamRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  :nth-child(1) {
    grid-area: keeper;
  }
  :nth-child(2) {
    grid-area: defenders;
  }
  :nth-child(3) {
    grid-area: midfielders;
  }
  :nth-child(4) {
    grid-area: forwards;
  }
  :nth-child(5) {
    grid-area: subs;
    display: flex;
    flex-direction: column;
    ::before {
      content: 'Bench';
      font-size: 0.8rem;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
      color: #d0d0d0;
      margin-bottom: 0.7rem;
    }
  }
`

export const TeamRow = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background: ${props => (props.isDraggingOver ? '#fff4e0' : null)};
`

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 17vw;
  gap: 0.5vw;
  background: ${props => (props.isDragging ? '#00000020' : null)};
  transition: background-color 0.3s ease-in-out 0.3s;
  border-radius: 5px;
`

export const SubsSection = styled.div`
  background: var(--color-grey-1);
  border-top: 1px solid var(--color-grey-2);
`

export const Fixture = styled.p`
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  width: inherit;
`
export const Name = styled(Fixture)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const NameInBid = styled(Name)`
  padding: 0 0.4rem;
  width: fit-content;
  background: var(--color-warning);
  border-radius: 3px;
  display: inline;
`

export const Keeper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 0.6em;
  padding: 0.3rem 0;
  align-items: center;
  gap: 0.5vw;
`

export const UpdateTeams = styled.div`
  width: 80%;
  margin: 30px auto 25px;
`
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TeamMgmtGrid = styled.div`
  display: grid;
  row-gap: 6px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
`
export const TeamMgmTitle = styled.h5`
  margin: 0;
  text-align: center;
  font-weight: 800;
  font-size: 0.7rem;
`
export const TeamMgmCopy = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  border-right: 1px solid #ddd;
`
export const TeamMgmCopyTime = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 3px 0;
`
