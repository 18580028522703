import * as React from 'react'
const SvgNavProfile = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth={2}
    className='nav_profile_svg__feather nav_profile_svg__feather-user'
    {...props}
  >
    <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
    <circle cx={12} cy={7} r={4} />
  </svg>
)
export default SvgNavProfile
