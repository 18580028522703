import React from 'react'
import styled from 'styled-components'
import Avatar from 'components/design-elements/Avatar'

export default function CardHeader({
  manager = '',
  minHeaderHeight = '66px',
  showManager = true,
  showInfo = true,
  gap,
  children,
}) {
  return (
    <Container $minHeaderHeight={minHeaderHeight}>
      {showManager && (
        <ManagerContainer>
          <Avatar manager={manager} size='35px' />
          <ManagerName>{manager}</ManagerName>
        </ManagerContainer>
      )}
      {showInfo && <HeaderInfo gap={gap}>{children}</HeaderInfo>}
      {!showInfo && children}
    </Container>
  )
}

const Container = styled.div`
  min-height: ${props => props.$minHeaderHeight};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.8rem 1rem;
  background: var(--color-grey-1);
  border-bottom: 1px solid var(--color-grey-2);
  align-items: center;
`

const ManagerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`

const ManagerName = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
`
const HeaderInfo = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: center;
  gap: ${({ gap }) => gap || '0px'};
`
