import styled, { css } from 'styled-components'

export const PageWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 1rem 1rem;

  // Adds the menu height to margin bottom
  ${({ dashboard }) =>
    dashboard &&
    css`
      padding: 1.5rem;
    `}

  // Adds the menu height to margin bottom
  ${({ bottom0 }) =>
    bottom0 &&
    css`
      margin-bottom: calc(var(--menu-height));
    `}

  // Adds the menu height plus 20px to margin bottom
  ${({ bottom20 }) =>
    bottom20 &&
    css`
      margin-bottom: calc(var(--menu-height) + 20px);
    `}  
  
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: var(--box-radius);
    `}
`
