import useVersionFlag from 'hooks/useVersionFlag'

export default function useCurrentDST() {
  const { isUK } = useVersionFlag()

  const now = new Date()
  const timeZone = isUK ? 'Europe/London' : 'America/New_York'
  const timeFormat = isUK ? 'en-GB' : 'en-US'
  const shortTimeZoneName = Intl.DateTimeFormat(timeFormat, {
    timeZoneName: 'short',
    timeZone,
  })
    .formatToParts(now)
    .find(part => part.type === 'timeZoneName').value

  return { shortTimeZoneName }
}
