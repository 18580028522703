import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useAvatar from 'hooks/useAvatar'

export default function Avatar({ manager = '', size = '35px' }) {
  const { getPhotoURL } = useAvatar()

  const photoURL = useMemo(() => {
    try {
      return getPhotoURL(manager)
    } catch (error) {
      console.error('Failed to fetch photo URL:', error)
      return 'img/profile/default-profile-img.png'
    }
  }, [manager, getPhotoURL])

  return (
    <ManagerAvatar
      $size={size}
      src={photoURL || 'img/profile/default-profile-img.png'}
      alt={`${manager}'s avatar`}
    />
  )
}

Avatar.propTypes = {
  manager: PropTypes.string,
  size: PropTypes.string,
}

const ManagerAvatar = styled.img`
  max-width: ${props => props.$size};
  max-height: ${props => props.$size};
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`
