import React from 'react'
import styled from 'styled-components'
import { PageWrapper } from 'components/design-elements/Wrappers.styled'
import { Button } from 'components/buttons/Button'
import Header from 'components/header/Header'
import { Link } from 'react-router-dom'

export default function FourOFour(props) {
  return (
    <>
      <Header header={'WTF IS GOING ON!'} />
      <PageWrapper>
        <Container>
          <Grid>
            <div style={{ marginBottom: '2em' }}>
              <img src='/img/emptyState/emptyState_404aliens.svg' alt='Something went wrong' />
            </div>
            <div>
              <span style={{ fontSize: '1.2em', fontWeight: '800' }}>
                Something weird just happened!
              </span>
              <br />
              <br />
              <span>
                Go back to the home screen and try again. If the problem persists contact your local
                admin.
              </span>
            </div>
            <br />
            <br />
            <Link to='/'>
              <Button primary>GO HOME</Button>
            </Link>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--color-grey-2);
  display: grid;
  place-items: center;
  inset: 0;
  z-index: 300;
`
const Grid = styled.div`
  width: 80%;
  height: 50%;
  display: grid;
  place-items: center;
  text-align: center;
  color: #7d7d7d;
`
