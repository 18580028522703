import * as React from 'react'
const SvgNavHome = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={32} height={38} fill='none' {...props}>
    <path
      fill='currentColor'
      d='M1.151 35v-7.755h1.716v3.113H6.42v-3.113h1.727V35H6.42v-3.223H2.867V35zm11.103.121q-.891 0-1.551-.352a2.6 2.6 0 0 1-1.012-1.012q-.352-.65-.352-1.55 0-.892.352-1.54a2.5 2.5 0 0 1 1.012-1.002q.66-.352 1.55-.352.892 0 1.552.352t1.012 1.001q.363.65.363 1.54 0 .902-.363 1.551a2.5 2.5 0 0 1-1.012 1.012q-.66.352-1.551.352m0-1.254q.572 0 .902-.407.34-.407.34-1.254t-.34-1.243q-.33-.396-.902-.396t-.913.396q-.33.396-.33 1.243t.33 1.254q.34.407.913.407M16.252 35v-4.125a12 12 0 0 0-.088-1.452h1.595l.121 1.034h-.132q.243-.527.715-.836.474-.308 1.122-.308.639 0 1.067.308.441.297.627.935h-.176q.242-.572.77-.902a2.16 2.16 0 0 1 1.2-.34q.946 0 1.407.56t.462 1.76V35h-1.66v-3.31q0-.584-.188-.837-.186-.253-.605-.253-.495 0-.78.352-.287.352-.287.968V35h-1.66v-3.31q0-.584-.188-.837-.186-.253-.605-.253-.495 0-.78.352-.276.352-.276.968V35zm12.828.121q-1.453 0-2.266-.78Q26 33.557 26 32.216q0-.87.34-1.518.352-.65.969-1.012.627-.375 1.463-.374.825 0 1.375.352.56.353.836.99.286.627.286 1.463v.385h-3.905v-.825h2.695l-.187.154q0-.693-.286-1.045-.276-.363-.803-.363-.584 0-.902.43-.309.428-.308 1.231v.176q0 .814.396 1.21.407.396 1.166.396.45 0 .847-.11.407-.12.77-.374l.462 1.1a3.1 3.1 0 0 1-.968.473q-.54.165-1.166.165M25.6 19.334a1.066 1.066 0 0 1-1.067 1.066H7.468A1.066 1.066 0 0 1 6.4 19.334V8.123c0-.33.152-.64.412-.843L15.345.644a1.066 1.066 0 0 1 1.31 0l8.534 6.636c.26.203.411.514.411.843zm-2.133-1.067V8.643L16 2.836 8.534 8.643v9.624z'
    />
  </svg>
)
export default SvgNavHome
