import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from 'contexts/AuthContext'

export const MenuItem = ({ path, icon, label, handleLinkClick }) => {
  const { currentUser } = useAuth()
  const { uid } = currentUser

  const isProfile = label === 'Profile'
  const jimmyFlag =
    uid === process.env.REACT_APP_ADMIN_UID_UK
      ? ' 🇬🇧'
      : uid === process.env.REACT_APP_ADMIN_UID_USA
      ? ' 🇺🇸'
      : ''

  return (
    <NavLink
      key={path}
      to={`/${path}`}
      onClick={handleLinkClick}
      style={({ isActive }) => {
        return {
          background: isActive ? 'white' : 'inherit',
          color: isActive ? 'var(--color-primary)' : 'inherit',
        }
      }}
    >
      <MenuItemGrid>
        <MenuIcon>{icon}</MenuIcon>
        <MenuLabel>
          {label}
          {isProfile && jimmyFlag}
        </MenuLabel>
      </MenuItemGrid>
    </NavLink>
  )
}

const MenuItemGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  gap: 1.1rem;
  padding-left: 1rem;
  height: 7vh;
`
const MenuIcon = styled.div`
  justify-self: center;
  line-height: 0;
`
const MenuLabel = styled.p`
  font-weight: 700;
  font-size: 1.1rem;
`
