import * as React from 'react'
const SvgIconX = ({ scale }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24 * scale}
    height={24 * scale}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth={3}
  >
    <path d='M18 6 6 18M6 6l12 12' />
  </svg>
)
export default SvgIconX
