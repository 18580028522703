import React from 'react'
import { useLeagueData } from 'hooks/useApiCall'
import CustomErrorBoundary from 'views/utility/CustomErrorBoundary'
import LeagueData from './LeagueData'
import { Outlet, useOutletContext } from 'react-router-dom'
import Loading from 'components/loader/Loading'
import Header from 'components/header/Header'
import FourOFour from 'views/utility/FourOFour'
import { PageWrapper } from 'components/design-elements/Wrappers.styled'
import { TableGrid, TableHeaderCell } from 'components/design-elements/Tables.styled'
import NavigationTabs from 'components/navigation/NavigationTabs'

/*====================
LEAGUES PARENT COMPONENT
====================*/

export default function League() {
  const { leagueLoading, leagueError, leagueData } = useLeagueData()

  if (leagueError) return <FourOFour />
  if (leagueLoading) return <Loading leagueLoading={leagueLoading} />

  return (
    <>
      <Header header={'Leagues'} subHead />
      <PageWrapper bottom20>
        <NavigationTabs
          tab1='FF league'
          tab2='Bench league'
          pathname='/league'
          tab2url='bench'
          isSticky={false}
        />
        <CustomErrorBoundary>
          <Outlet context={[leagueData]} />
        </CustomErrorBoundary>
      </PageWrapper>
    </>
  )
}

/*========================================
LEAGUE CHILD COMPONENTS (FF & BENCH)
========================================*/

export function LeagueSwitcher({ bench }) {
  const [leagueData] = useOutletContext()
  const leagueHasStarted = Number(leagueData[0].Totals) > 0 // checks whether any points are in for the main league
  const benchLeagueHasStarted = Number(leagueData[0].bTotals) > 0 // checks whether any points are in for the bench league

  const leagueComponents = leagueData.map((league, index) => (
    <LeagueData
      key={league.Sort}
      position={league.Position}
      chevron={league.chev}
      team={league.Team}
      score={league.WeeklyScore}
      total={league.Totals}
      bench={bench}
      index={index}
      leagueHasStarted={leagueHasStarted}
    />
  ))
  const benchLeagueComponents = leagueData.map((league, index) => (
    <LeagueData
      key={league.bSort}
      position={league.bPosition}
      chevron={league.bchev}
      team={league.bTeam}
      score={league.bWeeklyScore}
      total={league.bTotals}
      bench={bench}
      index={index}
      leagueHasStarted={benchLeagueHasStarted}
    />
  ))

  return (
    <>
      <TableGrid league>
        <TableHeaderCell topLeft>Pos</TableHeaderCell>
        <TableHeaderCell></TableHeaderCell>
        <TableHeaderCell leftAlign>Team</TableHeaderCell>
        <TableHeaderCell>Week Score</TableHeaderCell>
        <TableHeaderCell topRight>Total</TableHeaderCell>

        {!bench && leagueComponents}
        {bench && benchLeagueComponents}
      </TableGrid>
    </>
  )
}
