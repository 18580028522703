import { useCallback, useRef } from 'react'
import ReactTags from 'react-tag-autocomplete'
import { InputContainer, InputLabels } from './Input.styled'
import './autoInput.css'

export default function AutoInput({
  inputLabel,
  selected,
  setSelected,
  placeholderText = 'Add a tag',
  labelText = 'Select from list',
  noOptionsText = 'No options found',
  data,
}) {
  const reactTags = useRef()

  const onDelete = useCallback(
    tagIndex => {
      setSelected(selected.filter((_, i) => i !== tagIndex))
    },
    [selected]
  )

  const onAddition = useCallback(
    newTag => {
      setSelected([...selected, newTag])
    },
    [selected]
  )

  return (
    <InputContainer>
      <InputLabels>{inputLabel}</InputLabels>
      <ReactTags
        labelText={labelText}
        noSuggestionsText={noOptionsText}
        placeholderText={placeholderText}
        ref={reactTags}
        tags={selected}
        suggestions={data}
        onDelete={onDelete}
        onAddition={onAddition}
      />
    </InputContainer>
  )
}
