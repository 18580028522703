import React from 'react'
import styled from 'styled-components'

export default function Header({ header, subHead }) {
  return (
    <HeaderContainer subHead={subHead}>
      <Title>{header}</Title>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  display: grid;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  background: linear-gradient(180deg, #ffffff 35%, rgba(255, 255, 255, 0) 90%);
  padding: ${props => (props.subHead ? '1rem 1rem 0 1rem' : '1rem')};
`

const Title = styled.h1`
  font-size: 2em;
  font-weight: 1000;
  text-transform: uppercase;
`
