import * as React from 'react'
const SvgNavAuction = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.667}
      d='M10 .833a2.5 2.5 0 0 0-2.5 2.5V10a2.5 2.5 0 1 0 5 0V3.333a2.5 2.5 0 0 0-2.5-2.5'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.667}
      d='M15.833 8.333V10a5.833 5.833 0 1 1-11.666 0V8.333M10 15.833v3.334M6.667 19.167h6.666'
    />
  </svg>
)
export default SvgNavAuction
