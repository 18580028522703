import React from 'react'
import Loading from 'components/loader/Loading'
import { useOutletContext } from 'react-router-dom'
import ManageMyTeam from './ManageMyTeam'
import { myTeamFormatter } from '../utils/myTeamFormatter'

export default function MyTeam() {
  const { teamsData, userName, teamsRefetch: refetch } = useOutletContext()
  const userTeamData = teamsData.filter(name => name.manager === userName)

  const timeStampRead = userTeamData[0].lastUpdatedRead

  const { defendersArray, midfieldArray, forwardArray, subsArray } =
    myTeamFormatter(userTeamData)

  return (
    <>
      {!defendersArray ? (
        <Loading />
      ) : (
        <ManageMyTeam
          defendersArray={defendersArray}
          midfieldArray={midfieldArray}
          forwardArray={forwardArray}
          subsArray={subsArray}
          userName={userName}
          userTeamData={userTeamData}
          timeStampRead={timeStampRead}
          refetch={refetch}
        />
      )}
    </>
  )
}
