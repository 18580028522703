import * as React from 'react'
const SvgNavChristmas = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={26} fill='none' {...props}>
    <path
      fill='currentColor'
      d='m19.859 20.134-3.558-4.88h1.086a.766.766 0 0 0 .625-1.205l-3.558-4.907h1.086a.766.766 0 0 0 .625-1.206L10.598.288c-.272-.384-.924-.384-1.223 0l-5.54 7.62a.766.766 0 0 0 .625 1.206h1.086l-3.558 4.907a.766.766 0 0 0 .625 1.206h1.086L.141 20.134a.766.766 0 0 0 .625 1.206h7.958v3.372c0 .712.57 1.288 1.276 1.288s1.277-.576 1.277-1.288V21.34h7.957a.77.77 0 0 0 .68-.411c.135-.247.108-.548-.055-.795m-17.626-.302 3.557-4.906a.762.762 0 0 0-.624-1.206H4.079l3.558-4.907a.766.766 0 0 0-.625-1.206H5.927L10 2.015l4.047 5.592H12.96a.766.766 0 0 0-.624 1.206l3.557 4.907h-1.086a.766.766 0 0 0-.625 1.206l3.558 4.906z'
    />
  </svg>
)
export default SvgNavChristmas
