import { useQuery } from '@tanstack/react-query'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import useVersionFlag from './useVersionFlag'

export default function useReactQuery(
  sheetId,
  key,
  staleVal,
  enabledState = true
) {
  const { SPREADSHEET_ID, CLIENT_EMAIL, PRIVATE_KEY } = useVersionFlag()

  const SHEET_ID = sheetId
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

  const getData = async () => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      })

      await doc.loadInfo()
      const sheet = doc.sheetsById[SHEET_ID]
      const rows = await sheet.getRows()
      console.log('🌐', key)
      return rows
    } catch (e) {
      throw new Error(`Whoops! Looks like the sheet '${key}' failed to load`)
    }
  }

  return useQuery([key], getData, {
    staleTime: staleVal,
    enabled: enabledState,
    retry: 10,
  })
}
