import styled from 'styled-components'

/****************
GLASS CONTAINER
****************/

export const GlassGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-areas:
    'profile cash'
    'profile transfers';
`

export const Glass = styled.div`
  padding: 1em;
  background: #ffffff20;
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(35px);
  border-radius: var(--box-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const Cash = styled(Glass)`
  grid-area: cash;
  gap: 0.3rem;
`
export const Transfers = styled(Cash)`
  grid-area: transfers;
`

/****************
PROFILE COMPONENT
****************/

export const Profile = styled(Glass)`
  grid-area: profile;
  gap: 1rem;
`

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
`
//TODO: this should use the Avatar component
export const standard = '27vw'
export const max = '110px'

export const Avatar = styled.img`
  width: ${standard};
  height: ${standard};
  max-width: ${max};
  max-height: ${max};
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25);
`

export const Name = styled.p`
  text-align: center;
  color: white;
  font-size: 1.7rem;
  font-weight: 900;
`

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  gap: 1vw;
  background: #ffffff10;
  padding: 0.2rem 0.4rem;
  border-radius: var(--box-radius);
`
export const Plus = styled.p`
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
`

/****************
POINTS COMPONENT
****************/

export const PointsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`
export const Container = styled.div`
  position: relative;
`
export const Label = styled.p`
  font-size: 0.9rem;
  font-weight: 900;
  color: #ffffff80;
  text-align: center;
`
export const Info = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  text-align: center;
`
export const InfoLarge = styled(Info)`
  font-size: 2rem;
`
export const Up = styled.span`
  color: white;
  font-family: system-ui;
  font-weight: 600;
  ::after {
    content: '↑';
  }
`
export const Down = styled(Up)`
  ::after {
    content: '↓';
  }
`

/****************
CASH TRANSFERS
****************/

export const Icon = styled.img`
  height: 25px;
`
