import styled, { css } from 'styled-components'

export const Button = styled.button`
  background: #aaa;
  color: white;
  border: none;
  position: relative;
  border-radius: var(--box-radius);
  width: 100%;
  height: 3.5rem;
  font-weight: 900;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 1em;
  cursor: pointer;
  :disabled {
    opacity: 0.3;
  }

  ${props =>
    props.primary &&
    css`
      background: var(--color-primary);
      color: white;
    `}

  ${props =>
    props.secondary &&
    css`
      background: #ddd;
      color: black;
    `}

  ${props =>
    props.warning &&
    css`
      background: var(--color-red);
      color: white;
    `}

  ${props =>
    props.logout &&
    css`
      background: white;
      border: 2px solid var(--color-red);
      color: var(--color-red);
      font-weight: 900;
    `}
`
