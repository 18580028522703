import {
  NavMarket,
  NavAuction,
  NavLive,
  NavBalance,
  NavMomWinners,
  NavSideBets,
  NavChristmas,
  NavFfcup,
  NavSummer,
  NavHallOfFame,
  NavRules,
  NavProfile,
} from 'icons'

export const menuItems = [
  {
    icon: <NavMarket />,
    label: 'Market',
    path: 'the-market',
  },
  {
    icon: <NavAuction />,
    label: 'Auction Room',
    path: 'auction-room',
  },
  {
    icon: <NavLive />,
    label: 'Live Scores',
    path: 'live-scores',
  },
  {
    icon: <NavBalance />,
    label: 'Balance',
    path: 'balance',
  },
  {
    icon: <NavMomWinners />,
    label: 'MoM Winners',
    path: 'mom-winners',
  },
  {
    icon: <NavChristmas />,
    label: 'Christmas Cup',
    path: 'christmas-cup',
  },
  {
    icon: <NavFfcup />,
    label: 'FF Cup',
    path: 'ff-cup',
  },
  {
    icon: <NavSideBets />,
    label: 'Side Bets',
    path: 'side-bets',
  },
  {
    icon: <NavSummer />,
    label: 'Summer Auction',
    path: 'summer-auction',
  },
  {
    icon: <NavHallOfFame />,
    label: 'Hall of Fame',
    path: 'hall-of-fame',
  },
  {
    icon: <NavRules />,
    label: 'Rules',
    path: 'rules',
  },
  {
    icon: <NavProfile />,
    label: 'Profile',
    path: 'profile',
  },
]
