import React, { useState } from 'react'
import BottomNavItem, { NavListItem } from './BottomNavItem'
import Burger from 'components/navigation/bottom-nav/Burger'
import Menu from 'components/navigation/menu/Menu'
import styled from 'styled-components'
import './bottomNav.css'
import { NavHome, NavTeams, NavTransfers, NavLeague } from 'icons'

const bottomNavItems = [
  {
    icon: <NavHome />,
    path: '/',
  },
  {
    icon: <NavTransfers />,
    path: 'transfers',
  },
  {
    icon: <NavTeams />,
    path: 'teams',
  },
  {
    icon: <NavLeague />,
    path: 'league',
  },
]

export default function BottomNav() {
  const [open, setOpen] = useState(false)

  const handleLinkClick = () => {
    setOpen(false)
  }

  return (
    <>
      <Menu open={open} setOpen={setOpen} handleLinkClick={handleLinkClick} />
      <Nav>
        <NavList>
          {bottomNavItems.map(item => (
            <BottomNavItem
              key={item.path}
              path={item.path}
              icon={item.icon}
              handleLinkClick={handleLinkClick}
            />
          ))}
          <NavListItem>
            <Burger open={open} setOpen={setOpen} />
          </NavListItem>
        </NavList>
      </Nav>
    </>
  )
}

const Nav = styled.nav`
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--menu-height);
  max-width: inherit;
  z-index: var(--zi-bottom-nav);
  -webkit-box-shadow: 0px -4px 26px -4px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px -4px 26px -4px rgba(0, 0, 0, 0.12);
  box-shadow: 0px -4px 26px -4px rgba(0, 0, 0, 0.12);
  a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 10px;
  }
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
`
