import * as React from 'react'
const SvgIconChevronUp = ({ scale = 1, stroke = '#000' }) => {
  const w = 19 * scale
  const h = 12 * scale

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={w} height={h} fill='none' viewBox='0 0 19 12'>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='m2 9 7.5-7L17 9'
      />
    </svg>
  )
}
export default SvgIconChevronUp
