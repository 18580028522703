import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export default function BottomNavItem({ path, icon, handleLinkClick }) {
  return (
    <NavListItem className='ripple'>
      <NavLink
        to={path}
        onClick={handleLinkClick}
        style={({ isActive }) => {
          return {
            color: isActive ? 'var(--color-primary)' : 'black',
          }
        }}
      >
        <NavItems>{icon}</NavItems>
      </NavLink>
    </NavListItem>
  )
}

export const NavListItem = styled.li`
  text-align: center;
  width: 100px;
  cursor: pointer;
`

const NavItems = styled.span`
  height: 100%;
`
