import * as React from 'react'
const SvgNavHallOfFame = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={26} height={25} fill='none' {...props}>
    <path
      fill='currentColor'
      d='M19.654 24.997a1 1 0 0 1-.463-.115l-6.677-3.698-6.677 3.698a.96.96 0 0 1-1.027-.064.94.94 0 0 1-.398-.938l1.284-7.883-5.431-5.574a.975.975 0 0 1 .552-1.63L8.29 7.651l3.351-7.14a1.001 1.001 0 0 1 1.746 0l3.351 7.14 7.473 1.142a.975.975 0 0 1 .552 1.63l-5.444 5.574 1.284 7.883a.94.94 0 0 1-.398.938.95.95 0 0 1-.551.18m-7.14-5.88a.96.96 0 0 1 .462.127l5.406 2.992-1.04-6.42a.99.99 0 0 1 .308-.77l4.469-4.57-6.125-.939a.97.97 0 0 1-.732-.551l-2.748-5.804-2.696 5.752a.98.98 0 0 1-.732.553l-6.125.938 4.417 4.62c.21.225.305.533.257.836l-1.04 6.42 5.406-2.992H12a1 1 0 0 1 .514-.192'
    />
  </svg>
)
export default SvgNavHallOfFame
