import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Overlay from './Overlay'
import useFixedScroll from 'hooks/useFixedScroll'

export default function SlidingPanel({ open, setOpen, children, gwSelectmenuIsOpen }) {
  useFixedScroll(open)
  const eleRef = useRef()
  const dyRef = useRef(0)
  const viewportHeight = window.innerHeight
  const ele = eleRef.current

  /*================
    C O N T R O L S
  ================*/
  const offsetTop = 5 // Sets the desired distance in vh units from the top of the element to the top of the viewport
  const maxOffsetTop = offsetTop - 3 // Sets the desired MAX distance in vh units from the top of the element to the top of the viewport
  const tollerance = (100 - offsetTop) * 0.3 // Sets the point at which the element collapses down after drag
  const animationSpeed = '0.3s' // Sets the desired expansion and collapse speed of the panel
  /*================*/

  const [{ dy }, setOffset] = useState({
    dy: offsetTop,
  })

  /*==============================
    H A N D L E  M O U S E  D O W N
    ==============================*/

  const handleMouseDown = e => {
    const startPos = {
      y: (e.clientY / viewportHeight) * 100 - dy,
    }

    ele.style.transition = `transform ${animationSpeed} ease-in-out`

    // if (gwSelectmenuIsOpen) {
    //   return
    // }

    /*==============================
    H A N D L E  M O U S E  M O V E
    ==============================*/

    const handleMouseMove = e => {
      if (!ele) {
        return
      }

      // How far the mouse has been moved
      const dy = (e.clientY / viewportHeight) * 100 - startPos.y

      // Set the position of element adhering to the maxOffsetTop property
      ele.style.transform = `translateY(${dy <= maxOffsetTop ? maxOffsetTop : dy}vh)`

      // Remove transiation animation while moving
      ele.style.transition = 'none'

      setOffset({ dy })

      // Assigns dy value to ref, so that handleMouseUp can get the last position
      dyRef.current = dy
    }

    /*=======================
    H A N D L E  M O U S E  U P
    ===========================*/

    const handleMouseUp = () => {
      // Gets last dy position after move
      const dy = dyRef.current
      // Resets dy
      setOffset({ dy: offsetTop })

      // Reassigns animation after moving element
      ele.style.transition = `transform ${animationSpeed} ease-in-out`

      // Conditional to say if the current dy position is greater than the desired tollerance
      // then move the element down off canvas (100vh), reset open state to false, and after that
      // remove the in-line transform property ELSE bounce it back to the top, and keep the open state true
      if (dy > tollerance) {
        ele.style.transform = 'translateY(100vh)'
        setOpen(false)
        setTimeout(() => {
          ele.style.removeProperty('transform')
          setOffset({ dy: 10 })
          dyRef.current = 0
        }, 1)
      } else {
        ele.style.transform = `translateY(${offsetTop}vh)`
        setOpen(true)
        setTimeout(() => {
          ele.style.removeProperty('transform')
          dyRef.current = 0
        }, 1)
      }

      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  /*=================================
    H A N D L E  T O U C H  S T A R T
    ==============================*/

  const handleTouchStart = e => {
    const touch = e.touches[0]

    const startPos = {
      y: (touch.clientY / viewportHeight) * 100 - dy,
    }

    ele.style.transition = `transform ${animationSpeed} ease-in-out`

    /*===========================
    H A N D L E  T O U C H  M O V E
    ==============================*/

    const handleTouchMove = e => {
      if (!ele || gwSelectmenuIsOpen) {
        return
      }
      const touch = e.touches[0]
      const dy = (touch.clientY / viewportHeight) * 100 - startPos.y

      // Set the position of element
      ele.style.transition = 'none'
      ele.style.transform = `translateY(${dy <= maxOffsetTop ? maxOffsetTop : dy}vh)`

      setOffset({ dy })
      dyRef.current = dy
    }

    /*============================
    H A N D L E  T O U C H  E N D
    ==============================*/

    const handleTouchEnd = () => {
      const dy = dyRef.current
      setOffset({ dy: offsetTop })
      ele.style.transition = `transform ${animationSpeed} ease-in-out`
      if (dy > tollerance) {
        ele.style.transform = 'translateY(100vh)'
        setOpen(false)
        setTimeout(() => {
          ele.style.removeProperty('transform')
          dyRef.current = 0
        }, 1)
      } else {
        ele.style.transform = `translateY(${offsetTop}vh)`
        setOpen(true)
        setTimeout(() => {
          ele.style.removeProperty('transform')
          dyRef.current = 0
        }, 1)
      }
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }

    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)
  }

  return (
    <>
      <FilterPanel
        ref={eleRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        $open={open}
        $offsetTop={offsetTop}
        $maxOffsetTop={maxOffsetTop}
        $animationSpeed={animationSpeed}
      >
        <ThumbPull>
          <ThumbPullLine />
          <ThumbPullLine />
        </ThumbPull>
        {children}
      </FilterPanel>
      <Overlay open={open} setOpen={setOpen} />
    </>
  )
}

const FilterPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  transform: ${({ $open, $offsetTop }) =>
    $open ? `translateY(${$offsetTop}vh)` : 'translateY(100vh)'};
  height: ${({ $offsetTop, $maxOffsetTop }) => 100 + $maxOffsetTop - $offsetTop}vh;
  width: 100vw;
  border-radius: 15px 15px 0 0;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px -2px 16px; // Optional box shadow, but not needed with overlay  */
  transition: transform ${({ $animationSpeed }) => $animationSpeed} ease-in-out;
  padding: 1rem;
  padding-bottom: calc(${({ $maxOffsetTop }) => $maxOffsetTop}vh + 2rem);
  z-index: var(--zi-filter);

  /* Indicate that the element is draggable */
  cursor: move;
  user-select: none;
  touch-action: none;
`

const ThumbPull = styled.div`
  display: flex;
  flex-direction: column;
  height: 1rem;
  justify-content: space-evenly;
  align-items: center;
`
const ThumbPullLine = styled.span`
  width: 30px;
  height: 1px;
  background: #bbb;
`
