import React, { useState, useEffect } from 'react'
import Loading from 'components/loader/Loading'
import CustomErrorBoundary from 'views/utility/CustomErrorBoundary'
import Header from 'components/header/Header'
import { PageWrapper } from 'components/design-elements/Wrappers.styled'
import FourOFour from 'views/utility/FourOFour'
import { useTeamsData } from 'hooks/useApiCall'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import NavigationTabs from 'components/navigation/NavigationTabs'
import Tooltip from 'components/design-elements/Tooltip'
import { NameInBid } from './Teams.styles'

export default function Teams() {
  const { teamsLoading, teamsError, teamsData, teamsRefetch } = useTeamsData()
  const { currentUser } = useAuth()
  const userName = currentUser.displayName
  const location = useLocation()
  const { pathname } = location
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  if (teamsError) return <FourOFour />
  if (teamsLoading) return <Loading teamsLoading={teamsLoading} />

  return (
    <>
      <Header header={'Teams'} subHead />
      <PageWrapper bottom0>
        <NavigationTabs
          tab1='All Teams'
          tab2='My Team'
          pathname='/teams'
          tab2url='my-team'
          onClickFunc={() => setOpen(!open)}
          showInfo={pathname === '/teams/my-team'}
        />
        {open && (
          <Tooltip openProp={{ state: open, setter: setOpen }}>
            Players highlighted <NameInBid>yellow</NameInBid> are subject to a pending transfer
          </Tooltip>
        )}
        <CustomErrorBoundary>
          <Outlet context={{ teamsData, userName, teamsRefetch }} />
        </CustomErrorBoundary>
      </PageWrapper>
    </>
  )
}
