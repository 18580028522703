import React from 'react'
import styled from 'styled-components'
import Transfer from 'components/patterns/Transfer'
import CardHeader from 'components/patterns/CardHeader'
import { Card } from 'components/design-elements/Card.styled'

export default function SingleTransfer({
  manager,
  outImage,
  inImage,
  firstNameOut,
  lastNameOut,
  firstNameIn,
  lastNameIn,
  pandl,
  inMil,
  outMil,
  type,
  auctionSpend,
  gw,
}) {
  return (
    <Card>
      <CardHeader manager={manager} gap={'0.5rem'}>
        <HeaderInfoLabel>GW{gw}</HeaderInfoLabel>
        <TransferMethod type={type}>{type}</TransferMethod>
      </CardHeader>

      <TransferBody>
        <Transfer
          outImage={outImage}
          inImage={inImage}
          firstNameOut={firstNameOut}
          lastNameOut={lastNameOut}
          firstNameIn={firstNameIn}
          lastNameIn={lastNameIn}
          pandl={pandl}
          inMil={inMil}
          outMil={outMil}
          auctionSpend={auctionSpend}
        />
      </TransferBody>
    </Card>
  )
}

const HeaderInfoLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 0.5;
`

const TransferMethod = styled.div`
  padding: 0.2rem 0.5rem;
  width: fit-content;
  background: ${({ type }) =>
    type === 'transfer' ? '#d0e4f3 ' : type === 'auction' ? '#ebdbdb' : '#dbebe1'};
  border-radius: 5px;
  font-size: 0.6rem;
  font-weight: 800;
  text-transform: capitalize;
`

const TransferBody = styled.div`
  padding: 1rem;
`
