import React, { useState } from 'react'
import styled from 'styled-components'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { useAuth } from 'contexts/AuthContext'
import ConfirmAction from 'components/patterns/ConfirmAction'
import useVersionFlag from 'hooks/useVersionFlag'
import Transfer from 'components/patterns/Transfer'
import { Card } from 'components/design-elements/Card.styled'

export default function CancelTransfer({
  refetch,
  outImage,
  inImage,
  firstNameOut,
  lastNameOut,
  firstNameIn,
  lastNameIn,
  pandl,
  inMil,
  outMil,
}) {
  const { currentUser } = useAuth()
  const userName = currentUser.displayName
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [hideTransfer, setHideTransfer] = useState('grid')
  const { SPREADSHEET_ID, CLIENT_EMAIL, PRIVATE_KEY } = useVersionFlag()

  const SHEET_ID = process.env.REACT_APP_SHEET_ID_POST_TRANSFERS
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

  const deleteTransfer = async () => {
    try {
      setErrorMessage('')
      setSuccess(false)
      setSubmitting(true)
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      })

      await doc.loadInfo()

      const sheet = doc.sheetsById[SHEET_ID]

      const rows = await sheet.getRows()

      rows.forEach((el, index) => {
        if (el.manager === userName && el.outImage === outImage && el.inImage === inImage) {
          rows[index].delete()
        }
      })
      setSuccess(true)
      setHideTransfer('none')
      refetch()
    } catch (e) {
      setErrorMessage('Whoops! something went wrong. Try again or contact your local admin.')
      console.error('Error: ', e)
    }
    setSubmitting(false)
  }

  return (
    <ShowHideContainer hide={hideTransfer}>
      <Card>
        <CancelTransferContainer>
          <Transfer
            outImage={outImage}
            inImage={inImage}
            firstNameOut={firstNameOut}
            lastNameOut={lastNameOut}
            firstNameIn={firstNameIn}
            lastNameIn={lastNameIn}
            pandl={pandl}
            inMil={inMil}
            outMil={outMil}
          />

          <ConfirmAction
            success={success}
            errorMessage={errorMessage}
            submitting={submitting}
            preActionMessage={`Are you sure you want to cancel this transfer for ${lastNameIn}?`}
            submittingMessage='Cancelling transfer'
            postActionMessage='Transfer cancelled!'
            buttonText='Cancel transfer'
            confirmFn={deleteTransfer}
          />
        </CancelTransferContainer>
      </Card>
    </ShowHideContainer>
  )
}

const ShowHideContainer = styled.div`
  display: ${props => props.hide || 'block'};
`
const CancelTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
`
