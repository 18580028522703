import * as React from 'react'
const SvgLongIconArrowUp = ({ scale = 1, fill, rotate = 0 }) => {
  const transform = `rotate(${rotate})`

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20 * scale}
      height={32 * scale}
      viewBox='0 0 24 32'
      fill='currentColor'
      transform={transform}
    >
      <path
        fill={fill}
        d='M11.407.587a2 2 0 0 0-2.83 0l-7.996 8a2.002 2.002 0 1 0 2.83 2.831l4.585-4.587V30c0 1.106.894 2 2 2a1.997 1.997 0 0 0 1.998-2V6.83l4.586 4.588a2.002 2.002 0 1 0 2.83-2.832l-7.997-8z'
      />
    </svg>
  )
}
export default SvgLongIconArrowUp
