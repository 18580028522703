import * as React from 'react'
const SvgIconChevronRight = ({ scale = 1, stroke = '#000' }) => {
  const w = 11 * scale
  const h = 19 * scale
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={w} height={h} fill='none' viewBox='0 0 11 19'>
      <path
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={4}
        d='m2 2 7 7.5L2 17'
      />
    </svg>
  )
}
export default SvgIconChevronRight
