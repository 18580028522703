import * as React from 'react'
const SvgIconArrowLeftShort = ({ scale = 1, fill = '#000' }) => {
  const w = 24 * scale
  const h = 20 * scale
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={w} height={h} fill='none' viewBox='0 0 24 20'>
      <path
        fill={fill}
        d='M.586 8.588a2 2 0 0 0 0 2.83l8 7.996a2.002 2.002 0 0 0 2.831-2.83L6.83 12H22c1.106 0 2-.893 2-1.999s-.894-2-2-2H6.83l4.587-4.584A2.002 2.002 0 0 0 8.586.586l-8 7.996z'
      />
    </svg>
  )
}
export default SvgIconArrowLeftShort
