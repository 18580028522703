import React, { useState } from 'react'
import { useAuth } from 'contexts/AuthContext'
import CustomErrorBoundary from 'views/utility/CustomErrorBoundary'
import Loading from 'components/loader/Loading'
import Header from 'components/header/Header'
import { Outlet, useLocation } from 'react-router-dom'
import FourOFour from 'views/utility/FourOFour'
import { useTransferHistoryData, useTransferData, useDashData } from 'hooks/useApiCall'
import { PageWrapper } from 'components/design-elements/Wrappers.styled'
import NavigationTabs from 'components/navigation/NavigationTabs'

export default function Transfers() {
  const { transferHistoryLoading, transferHistoryError, transferHistoryData } =
    useTransferHistoryData()
  const { transferLoading, transferError, transferData, transferRefetch } = useTransferData()
  const { dashData, dashError, dashLoading } = useDashData()
  const { currentUser } = useAuth()
  const userName = currentUser.displayName
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const { pathname } = location

  if (transferHistoryError || transferError || dashError) return <FourOFour />
  if (transferHistoryLoading) return <Loading transferHistoryLoading={transferHistoryLoading} />

  return (
    <>
      <Header header={'Transfers'} subHead />
      <PageWrapper bottom20>
        <NavigationTabs
          tab1='History'
          tab2='My transfers'
          pathname='/transfers'
          tab2url='my-transfers'
          onClickFunc={() => setOpen(true)}
          showFilter={pathname === '/transfers'}
        />
        <CustomErrorBoundary>
          <Outlet
            context={{
              transferHistoryData,
              transferData,
              transferLoading,
              transferError,
              dashData,
              dashLoading,
              dashError,
              open,
              userName,
              setOpen,
              transferRefetch,
            }}
          />
        </CustomErrorBoundary>
      </PageWrapper>
    </>
  )
}
