import * as React from 'react'
const SvgGear = ({ scale = 1, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32 * scale}
    height={32 * scale}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <mask id='gear_svg__a' fill='#fff'>
      <path
        fillRule='evenodd'
        d='M11.837 10.172a7.9 7.9 0 0 1 4.166-1.18c1.988.002 3.893.742 5.299 2.055 1.405 1.314 2.196 3.094 2.2 4.951 0 1.386-.44 2.741-1.264 3.893a7.4 7.4 0 0 1-3.365 2.581 8 8 0 0 1-4.333.399 7.68 7.68 0 0 1-3.84-1.918 6.88 6.88 0 0 1-2.052-3.587 6.6 6.6 0 0 1 .427-4.05 7.13 7.13 0 0 1 2.762-3.144M13.3 19.78c.8.5 1.74.766 2.703.766 1.29-.002 2.526-.481 3.438-1.334.912-.852 1.426-2.007 1.427-3.213 0-.899-.285-1.778-.82-2.525a4.8 4.8 0 0 0-2.183-1.675 5.2 5.2 0 0 0-2.811-.259 5 5 0 0 0-2.492 1.245 4.46 4.46 0 0 0-1.331 2.327 4.27 4.27 0 0 0 .277 2.627c.368.831.992 1.541 1.792 2.04'
        clipRule='evenodd'
      />
      <path
        fillRule='evenodd'
        d='m29.987 17.272 1.666 2.695a2.314 2.314 0 0 1 .165 2.122 15.8 15.8 0 0 1-2.24 3.654c-.245.29-.557.525-.912.687a2.8 2.8 0 0 1-1.141.248h-3.562a12.6 12.6 0 0 1-2.074 1.15l-1.738 2.825a2.5 2.5 0 0 1-.794.795 2.7 2.7 0 0 1-1.083.398c-1.508.205-3.04.205-4.55 0a2.7 2.7 0 0 1-1.081-.39 2.5 2.5 0 0 1-.788-.797l-1.764-2.86a12.3 12.3 0 0 1-2.094-1.12h-3.51c-.396-.001-.787-.086-1.144-.248a2.6 2.6 0 0 1-.916-.688A15.9 15.9 0 0 1 .19 22.095a2.28 2.28 0 0 1 .164-2.128l1.752-2.836a10 10 0 0 1-.06-1.133c0-.356.013-.725.053-1.125a1 1 0 0 0-.086-.148L.347 12.03a2.315 2.315 0 0 1-.158-2.122 15.8 15.8 0 0 1 2.238-3.654c.245-.29.557-.525.913-.687a2.8 2.8 0 0 1 1.141-.249H8.05q.977-.669 2.074-1.15l1.738-2.824c.197-.315.468-.585.791-.79.324-.206.692-.341 1.079-.397 1.508-.21 3.04-.21 4.549 0 .388.051.758.185 1.082.39.324.207.594.48.788.797L21.909 4.2a12.3 12.3 0 0 1 2.093 1.12h3.51c.396 0 .787.085 1.144.247s.67.397.916.688a15.9 15.9 0 0 1 2.239 3.648 2.28 2.28 0 0 1-.165 2.128l-1.751 2.837q.064.563.066 1.131c0 .357-.02.726-.06 1.126q.036.078.086.148m-6.03 6.946h3.561a13.4 13.4 0 0 0 1.85-3.064l-1.666-2.652a2.4 2.4 0 0 1-.434-1.538 8 8 0 0 0 0-1.931c-.048-.465.06-.931.31-1.335l1.79-2.898a13.3 13.3 0 0 0-1.89-3.058h-3.522a2.7 2.7 0 0 1-1.475-.443 11 11 0 0 0-1.718-.935 2.5 2.5 0 0 1-1.132-.966l-1.771-2.86a15.4 15.4 0 0 0-3.766 0l-1.725 2.836a2.5 2.5 0 0 1-1.133.972q-.905.394-1.718.935c-.43.287-.946.442-1.475.443H4.481A13.4 13.4 0 0 0 2.625 10.8l1.672 2.651c.319.456.47.995.434 1.538-.07.643-.07 1.29 0 1.932.048.465-.06.931-.309 1.335l-1.79 2.898a13.3 13.3 0 0 0 1.889 3.045h3.522a2.7 2.7 0 0 1 1.475.443c.54.36 1.116.673 1.718.935a2.5 2.5 0 0 1 1.133.966l1.77 2.86c1.25.155 2.517.155 3.767 0l1.725-2.835a2.5 2.5 0 0 1 1.132-.972 11 11 0 0 0 1.718-.935c.431-.287.946-.442 1.475-.443'
        clipRule='evenodd'
      />
    </mask>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M11.837 10.172a7.9 7.9 0 0 1 4.166-1.18c1.988.002 3.893.742 5.299 2.055 1.405 1.314 2.196 3.094 2.2 4.951 0 1.386-.44 2.741-1.264 3.893a7.4 7.4 0 0 1-3.365 2.581 8 8 0 0 1-4.333.399 7.68 7.68 0 0 1-3.84-1.918 6.88 6.88 0 0 1-2.052-3.587 6.6 6.6 0 0 1 .427-4.05 7.13 7.13 0 0 1 2.762-3.144M13.3 19.78c.8.5 1.74.766 2.703.766 1.29-.002 2.526-.481 3.438-1.334.912-.852 1.426-2.007 1.427-3.213 0-.899-.285-1.778-.82-2.525a4.8 4.8 0 0 0-2.183-1.675 5.2 5.2 0 0 0-2.811-.259 5 5 0 0 0-2.492 1.245 4.46 4.46 0 0 0-1.331 2.327 4.27 4.27 0 0 0 .277 2.627c.368.831.992 1.541 1.792 2.04'
      clipRule='evenodd'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='m29.987 17.272 1.666 2.695a2.314 2.314 0 0 1 .165 2.122 15.8 15.8 0 0 1-2.24 3.654c-.245.29-.557.525-.912.687a2.8 2.8 0 0 1-1.141.248h-3.562a12.6 12.6 0 0 1-2.074 1.15l-1.738 2.825a2.5 2.5 0 0 1-.794.795 2.7 2.7 0 0 1-1.083.398c-1.508.205-3.04.205-4.55 0a2.7 2.7 0 0 1-1.081-.39 2.5 2.5 0 0 1-.788-.797l-1.764-2.86a12.3 12.3 0 0 1-2.094-1.12h-3.51c-.396-.001-.787-.086-1.144-.248a2.6 2.6 0 0 1-.916-.688A15.9 15.9 0 0 1 .19 22.095a2.28 2.28 0 0 1 .164-2.128l1.752-2.836a10 10 0 0 1-.06-1.133c0-.356.013-.725.053-1.125a1 1 0 0 0-.086-.148L.347 12.03a2.315 2.315 0 0 1-.158-2.122 15.8 15.8 0 0 1 2.238-3.654c.245-.29.557-.525.913-.687a2.8 2.8 0 0 1 1.141-.249H8.05q.977-.669 2.074-1.15l1.738-2.824c.197-.315.468-.585.791-.79.324-.206.692-.341 1.079-.397 1.508-.21 3.04-.21 4.549 0 .388.051.758.185 1.082.39.324.207.594.48.788.797L21.909 4.2a12.3 12.3 0 0 1 2.093 1.12h3.51c.396 0 .787.085 1.144.247s.67.397.916.688a15.9 15.9 0 0 1 2.239 3.648 2.28 2.28 0 0 1-.165 2.128l-1.751 2.837q.064.563.066 1.131c0 .357-.02.726-.06 1.126q.036.078.086.148m-6.03 6.946h3.561a13.4 13.4 0 0 0 1.85-3.064l-1.666-2.652a2.4 2.4 0 0 1-.434-1.538 8 8 0 0 0 0-1.931c-.048-.465.06-.931.31-1.335l1.79-2.898a13.3 13.3 0 0 0-1.89-3.058h-3.522a2.7 2.7 0 0 1-1.475-.443 11 11 0 0 0-1.718-.935 2.5 2.5 0 0 1-1.132-.966l-1.771-2.86a15.4 15.4 0 0 0-3.766 0l-1.725 2.836a2.5 2.5 0 0 1-1.133.972q-.905.394-1.718.935c-.43.287-.946.442-1.475.443H4.481A13.4 13.4 0 0 0 2.625 10.8l1.672 2.651c.319.456.47.995.434 1.538-.07.643-.07 1.29 0 1.932.048.465-.06.931-.309 1.335l-1.79 2.898a13.3 13.3 0 0 0 1.889 3.045h3.522a2.7 2.7 0 0 1 1.475.443c.54.36 1.116.673 1.718.935a2.5 2.5 0 0 1 1.133.966l1.77 2.86c1.25.155 2.517.155 3.767 0l1.725-2.835a2.5 2.5 0 0 1 1.132-.972 11 11 0 0 0 1.718-.935c.431-.287.946-.442 1.475-.443'
      clipRule='evenodd'
    />
    <path
      stroke='#fff'
      strokeWidth={2}
      d='M11.837 10.172a7.9 7.9 0 0 1 4.166-1.18c1.988.002 3.893.742 5.299 2.055 1.405 1.314 2.196 3.094 2.2 4.951 0 1.386-.44 2.741-1.264 3.893a7.4 7.4 0 0 1-3.365 2.581 8 8 0 0 1-4.333.399 7.68 7.68 0 0 1-3.84-1.918 6.88 6.88 0 0 1-2.052-3.587 6.6 6.6 0 0 1 .427-4.05 7.13 7.13 0 0 1 2.762-3.144ZM13.3 19.78c.8.5 1.74.766 2.703.766 1.29-.002 2.526-.481 3.438-1.334.912-.852 1.426-2.007 1.427-3.213 0-.899-.285-1.778-.82-2.525a4.8 4.8 0 0 0-2.183-1.675 5.2 5.2 0 0 0-2.811-.259 5 5 0 0 0-2.492 1.245 4.46 4.46 0 0 0-1.331 2.327 4.27 4.27 0 0 0 .277 2.627c.368.831.992 1.541 1.792 2.04Z'
      clipRule='evenodd'
      mask='url(#gear_svg__a)'
    />
    <path
      stroke='#fff'
      strokeWidth={2}
      d='m29.987 17.272 1.666 2.695a2.314 2.314 0 0 1 .165 2.122 15.8 15.8 0 0 1-2.24 3.654c-.245.29-.557.525-.912.687a2.8 2.8 0 0 1-1.141.248h-3.562a12.6 12.6 0 0 1-2.074 1.15l-1.738 2.825a2.5 2.5 0 0 1-.794.795 2.7 2.7 0 0 1-1.083.398c-1.508.205-3.04.205-4.55 0a2.7 2.7 0 0 1-1.081-.39 2.5 2.5 0 0 1-.788-.797l-1.764-2.86a12.3 12.3 0 0 1-2.094-1.12h-3.51c-.396-.001-.787-.086-1.144-.248a2.6 2.6 0 0 1-.916-.688A15.9 15.9 0 0 1 .19 22.095a2.28 2.28 0 0 1 .164-2.128l1.752-2.836a10 10 0 0 1-.06-1.133c0-.356.013-.725.053-1.125a1 1 0 0 0-.086-.148L.347 12.03a2.315 2.315 0 0 1-.158-2.122 15.8 15.8 0 0 1 2.238-3.654c.245-.29.557-.525.913-.687a2.8 2.8 0 0 1 1.141-.249H8.05q.977-.669 2.074-1.15l1.738-2.824c.197-.315.468-.585.791-.79.324-.206.692-.341 1.079-.397 1.508-.21 3.04-.21 4.549 0 .388.051.758.185 1.082.39.324.207.594.48.788.797L21.909 4.2a12.3 12.3 0 0 1 2.093 1.12h3.51c.396 0 .787.085 1.144.247s.67.397.916.688a15.9 15.9 0 0 1 2.239 3.648 2.28 2.28 0 0 1-.165 2.128l-1.751 2.837q.064.563.066 1.131c0 .357-.02.726-.06 1.126q.036.078.086.148Zm-6.03 6.946h3.561a13.4 13.4 0 0 0 1.85-3.064l-1.666-2.652a2.4 2.4 0 0 1-.434-1.538 8 8 0 0 0 0-1.931c-.048-.465.06-.931.31-1.335l1.79-2.898a13.3 13.3 0 0 0-1.89-3.058h-3.522a2.7 2.7 0 0 1-1.475-.443 11 11 0 0 0-1.718-.935 2.5 2.5 0 0 1-1.132-.966l-1.771-2.86a15.4 15.4 0 0 0-3.766 0l-1.725 2.836a2.5 2.5 0 0 1-1.133.972q-.905.394-1.718.935c-.43.287-.946.442-1.475.443H4.481A13.4 13.4 0 0 0 2.625 10.8l1.672 2.651c.319.456.47.995.434 1.538-.07.643-.07 1.29 0 1.932.048.465-.06.931-.309 1.335l-1.79 2.898a13.3 13.3 0 0 0 1.889 3.045h3.522a2.7 2.7 0 0 1 1.475.443c.54.36 1.116.673 1.718.935a2.5 2.5 0 0 1 1.133.966l1.77 2.86c1.25.155 2.517.155 3.767 0l1.725-2.835a2.5 2.5 0 0 1 1.132-.972 11 11 0 0 0 1.718-.935c.431-.287.946-.442 1.475-.443Z'
      clipRule='evenodd'
      mask='url(#gear_svg__a)'
    />
  </svg>
)
export default SvgGear
