// Helper function to safely extract player IDs
function getPlayerId(columns, category, index) {
  try {
    return columns[category].items[index].id
  } catch (error) {
    return ''
  }
}

// Refactored helper function
export default function assignTeam(columns) {
  const def1string = getPlayerId(columns, 'DEF', 0)
  const def2string = getPlayerId(columns, 'DEF', 1)
  const def3string = getPlayerId(columns, 'DEF', 2)
  const def4string = getPlayerId(columns, 'DEF', 3)
  const def5string = getPlayerId(columns, 'DEF', 4)

  const mid1string = getPlayerId(columns, 'MID', 0)
  const mid2string = getPlayerId(columns, 'MID', 1)
  const mid3string = getPlayerId(columns, 'MID', 2)
  const mid4string = getPlayerId(columns, 'MID', 3)
  const mid5string = getPlayerId(columns, 'MID', 4)

  const fwd1string = getPlayerId(columns, 'FWD', 0)
  const fwd2string = getPlayerId(columns, 'FWD', 1)
  const fwd3string = getPlayerId(columns, 'FWD', 2)

  const sub1string = getPlayerId(columns, 'SUB', 0)
  const sub2string = getPlayerId(columns, 'SUB', 1)
  const sub3string = getPlayerId(columns, 'SUB', 2)

  return {
    def1string,
    def2string,
    def3string,
    def4string,
    def5string,
    mid1string,
    mid2string,
    mid3string,
    mid4string,
    mid5string,
    fwd1string,
    fwd2string,
    fwd3string,
    sub1string,
    sub2string,
    sub3string,
  }
}
