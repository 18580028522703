import React from 'react'
import styled from 'styled-components'
import buildversion from 'buildversion'

export default function VersionLabel() {
  return (
    <VersionLabelStyle>{`v${buildversion.buildMajor}.${buildversion.buildMinor}.${buildversion.buildRevision}`}</VersionLabelStyle>
  )
}

const VersionLabelStyle = styled.p`
  bottom: 10%;
  color: #ffffff;
  font-size: 0.7em;
  font-weight: 900;
  text-align: center;
  z-index: 101;
  position: absolute;
  top: 96%;
  left: 50%;
  transform: translate(-50%, -50%);
`
