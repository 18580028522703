import { useEffect } from 'react'

export default function useFixedScroll(open) {
  useEffect(() => {
    const body = document.body
    if (open) {
      body.classList.add('no-scroll')
    } else {
      body.classList.remove('no-scroll')
    }
    return () => {
      body.classList.remove('no-scroll')
    }
  }, [open])
}
