import React from 'react'
import styled from 'styled-components'
import { Card, CardContainer, CardH1, CardH3 } from 'components/design-elements/Card.styled'
import PlayerContainer from 'components/patterns/PlayerContainer'

export default function DashMyForm({ userDashData, gameWeek }) {
  // HIGH SCORES
  const formScoreHighName1 = userDashData[0].formScoreHighName1
  const formScoreHighName2 = userDashData[0].formScoreHighName2
  const formScoreHighName3 = userDashData[0].formScoreHighName3
  const formScoreHighId1 = userDashData[0].formScoreHighId1
  const formScoreHighId2 = userDashData[0].formScoreHighId2
  const formScoreHighId3 = userDashData[0].formScoreHighId3
  const formScoreHigh1 = userDashData[0].formScoreHigh1
  const formScoreHigh2 = userDashData[0].formScoreHigh2
  const formScoreHigh3 = userDashData[0].formScoreHigh3

  // LOW SCORES
  const formScoreLowName1 = userDashData[0].formScoreLowName1
  const formScoreLowName2 = userDashData[0].formScoreLowName2
  const formScoreLowName3 = userDashData[0].formScoreLowName3
  const formScoreLowId1 = userDashData[0].formScoreLowId1
  const formScoreLowId2 = userDashData[0].formScoreLowId2
  const formScoreLowId3 = userDashData[0].formScoreLowId3
  const formScoreLow1 = userDashData[0].formScoreLow1
  const formScoreLow2 = userDashData[0].formScoreLow2
  const formScoreLow3 = userDashData[0].formScoreLow3

  return (
    <Card>
      <CardContainer dashboard>
        <CardH3>My Team</CardH3>
        <CardH1>{gameWeek <= 7 ? gameWeek - 1 : '7'} Week form</CardH1>
        <Grid>
          <HotLabel>Hot</HotLabel>
          <Hot1>
            <PlayerForm
              secondName={formScoreHighName1}
              id={formScoreHighId1}
              score={formScoreHigh1}
            />
          </Hot1>
          <Hot2>
            <PlayerForm
              secondName={formScoreHighName2}
              id={formScoreHighId2}
              score={formScoreHigh2}
            />
          </Hot2>
          <Hot3>
            <PlayerForm
              secondName={formScoreHighName3}
              id={formScoreHighId3}
              score={formScoreHigh3}
            />
          </Hot3>
          <Gap />
          <ColdLabel>Cold</ColdLabel>
          <Cold1>
            <PlayerForm secondName={formScoreLowName1} id={formScoreLowId1} score={formScoreLow1} />
          </Cold1>
          <Cold2>
            <PlayerForm secondName={formScoreLowName2} id={formScoreLowId2} score={formScoreLow2} />
          </Cold2>
          <Cold3>
            <PlayerForm secondName={formScoreLowName3} id={formScoreLowId3} score={formScoreLow3} />
          </Cold3>
        </Grid>
      </CardContainer>
    </Card>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  grid-template-areas:
    'hotLabel gap coldLabel'
    'hot1 gap cold1'
    'hot2 gap cold2'
    'hot3 gap cold3';
`
const HotLabel = styled.h4`
  grid-area: hotLabel;
  font-style: italic;
  font-weight: 1000;
  text-transform: uppercase;
`
const ColdLabel = styled(HotLabel)`
  grid-area: coldLabel;
`
const Hot1 = styled.div`
  grid-area: hot1;
`
const Hot2 = styled.div`
  grid-area: hot2;
`
const Hot3 = styled.div`
  grid-area: hot3;
`
const Cold1 = styled.div`
  grid-area: cold1;
`
const Cold2 = styled.div`
  grid-area: cold2;
`
const Cold3 = styled.div`
  grid-area: cold3;
`

const Gap = styled.div`
  grid-area: gap;
  background: var(--color-grey-2);
`

const PlayerForm = ({ secondName, id, score }) => {
  return (
    <PlayerFormContainer>
      <PlayerContainer
        containerWidth='15vmin'
        source={id}
        secondName={secondName}
        nameSize='0.7'
        spacing='0'
      />
      <Score>{score}</Score>
    </PlayerFormContainer>
  )
}

const PlayerFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Score = styled.p`
  font-size: 6vmin;
  text-align: right;
  font-weight: 500;
  padding-top: 1rem;
`
