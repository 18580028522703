import React from 'react'
import styled from 'styled-components'
import {
  EmptyStateNoScores,
  EmptyStateNoPendingTransfers,
  EmptyStateAuctionRoomClosed,
  EmptyStateNothingToSee,
  EmptyStateNothingInList,
} from 'icons'

export const emptyStateData = {
  noPendingTransfers: {
    image: <EmptyStateNoPendingTransfers />,
    text: 'No pending transfers',
  },
  nothingToSee: {
    image: <EmptyStateNothingToSee />,
    text: 'Stuff will happen here soon, hang tight!',
  },
  auctionRoomClosed: {
    image: <EmptyStateAuctionRoomClosed scale='1.8' />,
    text: 'The Auction Room is closed',
  },
  noScores: {
    image: <EmptyStateNoScores />,
    text: 'No scores in yet!',
  },
  nothingInList: {
    image: <EmptyStateNothingInList scale='1.3' />,
    text: 'Got nothing for ya!',
  },
}

export default function EmptyState({ image, text = '' }) {
  return (
    <Container>
      {image}
      <Text>{text}</Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;
  width: 50vw;
`

const Text = styled.span`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 800;
`
