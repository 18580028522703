import React from 'react'
import styled from 'styled-components'
import { PlayerImage } from './PlayerImage'

export default function PlayerContainer({
  containerWidth = '18vw',
  firstName = null,
  secondName = null,
  nameSize = '0.7',
  spacing = '0.2',
  source,
  width,
  display,
  maxWidth,
  margin,
}) {
  return (
    <Container $containerWidth={containerWidth} $spacing={spacing}>
      <PlayerImage
        source={source}
        width='100%'
        display={display}
        maxWidth={maxWidth}
        margin={margin}
      />
      <NameContainer $spacing={spacing}>
        {firstName && <FirstName $nameSize={nameSize}>{firstName}</FirstName>}
        <SecondName $nameSize={nameSize}>{secondName}</SecondName>
      </NameContainer>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ $containerWidth }) => $containerWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ $spacing }) => `${$spacing}rem`};
`

const NameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: ${({ $spacing }) => `${$spacing / 3}rem`};
`
const FirstName = styled.p`
  font-weight: 300;
  line-height: 1.1;
  font-size: ${({ $nameSize }) => `${$nameSize}rem`};
`
const SecondName = styled(FirstName)`
  font-weight: 700;
  font-size: ${({ $nameSize }) => `${Number($nameSize) + 0.1}rem`};
`
