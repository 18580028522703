import * as React from 'react'
const SvgIconArrowRightShort = ({ scale = 1, fill = '#000' }) => {
  const w = 24 * scale
  const h = 20 * scale
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={w} height={h} fill='none' viewBox='0 0 24 20'>
      <path
        fill={fill}
        d='M23.414 11.412a2 2 0 0 0 0-2.83l-8-7.996a2.002 2.002 0 1 0-2.831 2.83L17.17 8H2C.894 8 0 8.894 0 10s.894 2 2 2h15.17l-4.587 4.584a2.002 2.002 0 1 0 2.831 2.83l8-7.996z'
      />
    </svg>
  )
}
export default SvgIconArrowRightShort
