import React from 'react'
import { Card, CardContainer, CardH1, CardH3 } from 'components/design-elements/Card.styled'
import DashScoresRow from './DashScoresRow'
import { filterByKeys } from 'utils/filterUtils'

export default function DashScores({ gameWeek, dashData }) {
  const keysToInclude = [
    'wpname',
    'wpid',
    'wp',
    'wpowned',
    'opname',
    'opid',
    'op',
    'opowned',
    'season',
  ]
  // utility function that filters data in an object by the keys passed in above
  const filteredTopPointsData = filterByKeys(dashData, keysToInclude)

  // checks if there is any points data to display
  const weekPointsExist = filteredTopPointsData[0].wpname !== 'Awaiting'
  const seasonPointsExist = filteredTopPointsData[0].opname !== 'Awaiting'

  // checks if the points accured in a week are exactly equal to the points accured in the season
  // if they are equal then the season points are not displayed
  const isFirstWeekOfSeason =
    filteredTopPointsData
      .map(entry => Number(entry.wp))
      .reduce((previousValue, currentValue) => previousValue + currentValue) ===
    filteredTopPointsData
      .map(entry => Number(entry.op))
      .reduce((previousValue, currentValue) => previousValue + currentValue)

  return (
    <>
      {weekPointsExist && (
        <Card>
          <CardContainer dashboard>
            <TopGameWeekScores gameWeek={gameWeek} topFive={filteredTopPointsData} />
          </CardContainer>
        </Card>
      )}
      {seasonPointsExist && !isFirstWeekOfSeason && (
        <Card>
          <CardContainer dashboard>
            <TopSeasonScores topFive={filteredTopPointsData} />
          </CardContainer>
        </Card>
      )}
    </>
  )
}

const TopGameWeekScores = ({ gameWeek, topFive }) => {
  const components = topFive.map(player => (
    <DashScoresRow
      key={player.wpid}
      image={player.wpid}
      name={player.wpname}
      points={player.wp}
      ffm={player.wpowned}
    />
  ))

  return (
    <>
      <CardH3>Top Scores</CardH3>
      <CardH1>Game Week {gameWeek}</CardH1>
      {components}
    </>
  )
}

const TopSeasonScores = ({ topFive }) => {
  const components = topFive.map(player => (
    <DashScoresRow
      key={player.opid}
      image={player.opid}
      name={player.opname}
      points={player.op}
      ffm={player.opowned}
    />
  ))

  const season = topFive[0].season

  return (
    <>
      <CardH3>Top Scores</CardH3>
      <CardH1>FF{season} Season</CardH1>
      {components}
    </>
  )
}
