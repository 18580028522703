import React, { useContext, useState, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useNavigate } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  verifyBeforeUpdateEmail,
  updatePassword,
  updateProfile,
} from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

const firebaseconfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseconfig)

const analytics = getAnalytics(app)
logEvent(analytics, 'analytics_initialised')

const auth = getAuth(app)
const storage = getStorage()

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  async function signup(name, email, password) {
    await createUserWithEmailAndPassword(auth, email, password)
    return updateProfile(auth.currentUser, {
      displayName: name,
    })
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  async function updateEmailAddress(email) {
    try {
      await verifyBeforeUpdateEmail(auth.currentUser, email)
      setTimeout(() => {
        navigate('/confirm-email')
        return signOut(auth)
      }, 2000)
    } catch (error) {
      console.error(error.message)
      throw error
    }
  }

  function newPassword(password) {
    return updatePassword(auth.currentUser, password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmailAddress,
    newPassword,
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}

// Storage functions
export const upload = async (file, currentUser, setUploading) => {
  try {
    const fileRef = ref(storage, currentUser.uid + '.png')

    setUploading(true)
    const snapshot = await uploadBytes(fileRef, file)
    const photoURL = await getDownloadURL(fileRef)

    updateProfile(currentUser, { photoURL })
  } catch (e) {
    alert('Whoops! something went wrong. Please contact your local admin.')
    console.error('Error: ', e)
  } finally {
    setUploading(false)
  }
}
