import styled from 'styled-components'

export const FilterChipsGrid = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 3.5rem 8fr;
  font-weight: 600;
  align-items: center;
`
export const FilterChipsContainer = styled.div`
  display: flex;
  overflow-x: auto;
`

export const FilterChips = styled.span`
  margin: 0 0.1rem;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  white-space: nowrap;
  background: var(--color-primary);
  font-size: 0.7rem;
  font-weight: 700;
  color: white;
  text-transform: capitalize;
`
