import React from 'react'
import Chevron from 'views/league/Chevron'
import { TableCell, MoneyMarker } from 'components/design-elements/Tables.styled'
import useVersionFlag from 'hooks/useVersionFlag'

export default function LeagueData({
  total,
  score,
  chevron,
  position,
  team,
  bench,
  index,
  leagueHasStarted,
}) {
  const { isUK } = useVersionFlag()
  const payoutPositions = isUK ? 8 : 9

  const editedPos = Number(position.replace('T', ''))
  const adjustedPostion = leagueHasStarted ? position : index + 1 // if league has started returns the index position + 1 to avoid T1, T1, etc

  return (
    <>
      <TableCell>
        {leagueHasStarted && !bench && editedPos <= payoutPositions ? (
          <MoneyMarker colorVal={editedPos} bench={bench} payoutPositions={payoutPositions} />
        ) : leagueHasStarted && bench && editedPos === 1 ? (
          <MoneyMarker colorVal={editedPos} bench={bench} />
        ) : null}
        {adjustedPostion}
      </TableCell>
      <TableCell>
        <Chevron score={score} total={total} chevron={chevron} />
      </TableCell>
      <TableCell leftAlign>{team}</TableCell>
      <TableCell>{score}</TableCell>
      <TableCell>{total}</TableCell>
    </>
  )
}
