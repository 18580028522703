import React from 'react'
import styled from 'styled-components'
import { Card, CardContainer, CardH1, CardH3 } from 'components/design-elements/Card.styled'
import useVersionFlag from 'hooks/useVersionFlag'
import { getConvertedDateTime } from 'hooks/useTimeConvert'
import { filterByKeys } from 'utils/filterUtils'

export default function DashFixtures({ fixturesData }) {
  const gameWeek = fixturesData[0].thisGameWeek
  const showFixtures = fixturesData[0].thisGwISOdate !== ''

  const keysToInclude = [
    'thisGwHome',
    'thisGwTime',
    'thisGwDay',
    'thisGwDate',
    'thisGwAway',
    'thisGwAbrvHome',
    'thisGwAbrvAway',
    'thisGwComp',
    'thisGwHomeScore',
    'thisGwAwayScore',
    'thisGwStatus',
    'thisGwISOdate',
  ]
  // utility function that filters data in an object by the keys passed in above
  const filteredFixturesData = filterByKeys(fixturesData, keysToInclude)

  const fixtureComponents = filteredFixturesData.map((fixture, index, arr) => {
    const prevFixture = arr[index - 1]
    const nextFixture = arr[index + 1]

    if (prevFixture?.thisGwDate !== fixture.thisGwDate) {
      return (
        <React.Fragment key={fixture.thisGwHome + fixture.thisGwAway}>
          <DateHeaders>
            {fixture.thisGwDay} {fixture.thisGwDate}
          </DateHeaders>
          <FixturesData
            key={fixture.thisGwHome + fixture.thisGwAway}
            home={fixture.thisGwHome}
            homeBadge={fixture.thisGwAbrvHome}
            time={fixture.thisGwTime}
            away={fixture.thisGwAway}
            awayBadge={fixture.thisGwAbrvAway}
            thisGwComp={fixture.thisGwComp}
            thisGwHomeScore={fixture.thisGwHomeScore}
            thisGwAwayScore={fixture.thisGwAwayScore}
            thisGwStatus={fixture.thisGwStatus}
            thisGwISOdate={fixture.thisGwISOdate}
            fixture={fixture}
            nextFixture={nextFixture}
          />
        </React.Fragment>
      )
    } else {
      return (
        <FixturesData
          key={fixture.thisGwHome + fixture.thisGwAway}
          home={fixture.thisGwHome}
          homeBadge={fixture.thisGwAbrvHome}
          time={fixture.thisGwTime}
          away={fixture.thisGwAway}
          awayBadge={fixture.thisGwAbrvAway}
          thisGwComp={fixture.thisGwComp}
          thisGwHomeScore={fixture.thisGwHomeScore}
          thisGwAwayScore={fixture.thisGwAwayScore}
          thisGwStatus={fixture.thisGwStatus}
          thisGwISOdate={fixture.thisGwISOdate}
          fixture={fixture}
          nextFixture={nextFixture}
        />
      )
    }
  })

  return (
    <>
      {showFixtures && (
        <Card>
          <CardContainer dashboard>
            <CardH3>Fixtures</CardH3>
            <CardH1>Game week {gameWeek}</CardH1>
            {fixtureComponents}
          </CardContainer>
        </Card>
      )}
    </>
  )
}

const FixturesData = ({
  home,
  away,
  homeBadge,
  awayBadge,
  thisGwComp,
  thisGwHomeScore,
  thisGwAwayScore,
  thisGwStatus,
  fixture,
  nextFixture,
  thisGwISOdate,
}) => {
  const { timeLondon, timeNY } = getConvertedDateTime(thisGwISOdate)

  const { isUK } = useVersionFlag()
  const isLastFixture = nextFixture?.thisGwDate !== fixture.thisGwDate
  const time = isUK ? timeLondon : timeNY

  return (
    <FixturesGrid lastFixture={isLastFixture}>
      <Home>{home}</Home>
      <HomeBadge>
        <img src={`/img/badges/badge_${homeBadge}.svg`} alt='Badge' style={{ height: '6vw' }} />
      </HomeBadge>
      <Time>
        {thisGwStatus === 'complete' ? (
          <ScoreBox>
            {thisGwHomeScore} - {thisGwAwayScore}
          </ScoreBox>
        ) : (
          time
        )}
        <br />
        {thisGwComp === 'epl' ? 'PL' : 'FA'}
      </Time>
      <AwayBadge>
        <img src={`/img/badges/badge_${awayBadge}.svg`} alt='Badge' style={{ height: '6vw' }} />
      </AwayBadge>
      <Away>{away}</Away>
    </FixturesGrid>
  )
}

const DateHeaders = styled.div`
  text-align: center;
  padding: 10px 0;
  background: var(--color-grey-1);
  color: black;
  font-weight: 800;
  border-radius: 5px;
  border-bottom: 1px solid #ccc;
  margin-top: 0.5rem;
`

const FixturesGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 30% 10% 20% 10% 30%;
  grid-template-rows: auto;
  padding: 15px 0;
  align-items: center;
  grid-template-areas: 'home homeBadge time awayBadge away';
  border-bottom: ${props => (props.lastFixture ? null : '1px solid var(--color-grey-2)')};
  :last-child {
    margin-bottom: 0px;
    border-bottom: none;
    padding: 15px 0 0 0;
  }
`
const fontSize = '0.7em'
const fontWeight = '700'

const Home = styled.div`
  grid-area: home;
  text-align: right;
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  padding: 0 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Away = styled(Home)`
  grid-area: away;
  text-align: left;
  padding: 0 0 0 10px;
`
const HomeBadge = styled.div`
  grid-area: homeBadge;
  justify-self: center;
`
const AwayBadge = styled(HomeBadge)`
  grid-area: awayBadge;
`
const Time = styled.div`
  grid-area: time;
  text-align: center;
  font-size: ${fontSize};
  font-weight: ${fontWeight};
`
const ScoreBox = styled.span`
  display: inline-block;
  background: #472b67;
  color: white;
  padding: 4px;
  margin: 2px 0;
  border-radius: 3px;
`
