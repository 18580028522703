import React from 'react'
import { Helmet } from 'react-helmet'
import styled, { keyframes } from 'styled-components'
import { SpinningCircle } from 'components/loader/Spinners'
import VersionLabel from 'components/design-elements/VersionLabel'

export default function Loading({
  dashLoading,
  topPointsLoading,
  auctionLoading,
  transferHistoryLoading,
  transferLoading,
  liveScoresLoading,
  balanceLoading,
  leagueLoading,
  hofLoading,
  dataBaseLoading,
  teamsLoading,
  maxTeamLoading,
  seasonListLoading,
  rulesLoading,
  summerAuctionLoading,
  gwLoading,
  h2hLoading,
  xmasCupLoading,
  fireBaseisLoading,
}) {
  return (
    <LoadingBackground>
      <Helmet>
        <meta name='theme-color' content='#c26e6f' />
      </Helmet>
      <LoadingContainer>
        <Logo>
          <img src='/img/assets/ff-logo.svg' alt='Loading...' />
        </Logo>
        <LoadingText>
          <div>LOADING</div>
          <div>
            {dashLoading
              ? 'dashboard'
              : topPointsLoading
              ? 'top points'
              : auctionLoading
              ? 'auctions'
              : transferHistoryLoading
              ? 'transfer history'
              : transferLoading
              ? 'transfers'
              : balanceLoading
              ? 'balance'
              : dataBaseLoading
              ? 'player database'
              : teamsLoading
              ? 'teams'
              : maxTeamLoading
              ? 'max allowances'
              : seasonListLoading
              ? 'season lists'
              : liveScoresLoading
              ? 'live scores'
              : leagueLoading
              ? 'leagues'
              : gwLoading
              ? 'game weeks'
              : rulesLoading
              ? 'rules'
              : hofLoading || summerAuctionLoading
              ? 'historical data'
              : h2hLoading || xmasCupLoading
              ? 'cup data'
              : fireBaseisLoading
              ? 'personal data'
              : null}
          </div>
        </LoadingText>
        <Spinner>
          <SpinningCircle color={'#fff'} />
        </Spinner>
      </LoadingContainer>
      <VersionLabel />
    </LoadingBackground>
  )
}

const LoadingBackground = styled.div`
  position: absolute;
  inset: 0;
  background: url('/img/assets/ff24bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  z-index: var(--zi-loading);
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
`
const blinker = keyframes`
  50% { opacity: 0.5; }
`

const LoadingContainer = styled.div`
  display: grid;
  position: relative;
  width: 50%;
  grid-template-columns: auto;
  grid-template-rows: 10vh 10vh 10vh;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  place-items: center;
  grid-template-areas:
    'logo'
    'loadingText'
    'spinner';
`
const Logo = styled.div`
  grid-area: logo;
  img {
    height: 10vh;
  }
`
const LoadingText = styled.div`
  grid-area: loadingText;
  display: grid;
  position: relative;
  grid-template-columns: auto;
  grid-template-rows: 15px 15px;
  color: #ffffff95;
  font-size: 0.8em;
  font-weight: 900;
  animation: ${blinker} 2s linear infinite;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`
const Spinner = styled.div`
  grid-area: spinner;
`
