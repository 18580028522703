import useCurrentDST from 'hooks/useCurrentDST'
import { useGwData } from 'hooks/useApiCall'
import { SpinningCircle } from 'components/loader/Spinners'

export default function NextTransferDate() {
  const { gwData } = useGwData()
  const { shortTimeZoneName } = useCurrentDST()

  if (!gwData) return <SpinningCircle color='#000' size='28px' />

  const nextGWTransferDay = gwData[0].nextGWTransferDay
  let nextGWTransferDayNoYear = nextGWTransferDay.split(',', 2).join(',')

  const transferDate = new Date(nextGWTransferDay)
  const newtransferDate = checkDate(transferDate, nextGWTransferDayNoYear)

  const displayTransferTime = gwData[0].nextGWTransferTime

  return (
    <>
      {newtransferDate} <br />
      {displayTransferTime} {shortTimeZoneName}
    </>
  )
}

function checkDate(date, returnedDate) {
  let today = new Date()
  today.setHours(0, 0, 0, 0)

  let tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  let inputDate = new Date(date)
  inputDate.setHours(0, 0, 0, 0)

  if (inputDate.getTime() === today.getTime()) {
    return 'Today'
  } else if (inputDate.getTime() === tomorrow.getTime()) {
    return 'Tomorrow'
  } else {
    return returnedDate
  }
}
