import useReactQuery from 'hooks/useReactQuery'

/*=======================================
Sets staleTime for queries in the cache
========================================*/
const oneMinute = 60 * 1000 // set to 20 seconds
const fiveMinutes = 5 * 60 * 1000 // set to 5 minutes
const tenMinutes = 10 * 60 * 1000 // set to 10 minutes
const thirtyMinutes = 30 * 60 * 1000 // set to 30 minutes
const sixtyDays = 60 * 24 * 60 * 60 * 1000 // set to 60 days
/*=======================================*/

/********************************************
  
  1   M I N U T E
 
 ********************************************/

// Dashboard data that displays the users stats in their home dashboard
export function useDashData(enabledState) {
  const {
    isLoading: dashLoading,
    isError: dashError,
    data: dashData,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_DASHBOARD,
    'dashboarddata',
    oneMinute,
    enabledState
  )
  return { dashData, dashError, dashLoading }
}

// Auction data from the AuctionRoom_POST tab depicting pending auction room bids
export function useAuctionData(enabledState) {
  const {
    data: auctionData,
    isLoading: auctionLoading,
    isError: auctionError,
    refetch: auctionRefetch,
    isFetching: auctionIsRefetching,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_POST_AUCTIONROOM,
    'auctiondata',
    oneMinute,
    enabledState
  )
  return { auctionData, auctionLoading, auctionError, auctionRefetch, auctionIsRefetching }
}

// Transfer data from Transfers_POST tab depicting pending transfers
export function useTransferData(enabledState) {
  const {
    isLoading: transferLoading,
    isError: transferError,
    data: transferData,
    refetch: transferRefetch,
    isFetching: transferIsRefetching,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_POST_TRANSFERS,
    'transferdata',
    oneMinute,
    enabledState
  )
  return {
    transferLoading,
    transferError,
    transferData,
    transferRefetch,
    transferIsRefetching,
  }
}

// Transfers data from the TransferHistory_READ tab
export function useTransferHistoryData(enabledState) {
  const {
    isLoading: transferHistoryLoading,
    isError: transferHistoryError,
    data: transferHistoryData,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_TRANSFER_HISTORY,
    'transferhistorydata',
    oneMinute,
    enabledState
  )
  return { transferHistoryLoading, transferHistoryError, transferHistoryData }
}

// Teams data from the Teams_READ tab
export function useTeamsData(enabledState) {
  const {
    isLoading: teamsLoading,
    isError: teamsError,
    data: teamsData,
    refetch: teamsRefetch,
  } = useReactQuery(process.env.REACT_APP_SHEET_ID_READ_TEAMS, 'teamsdata', oneMinute, enabledState)
  return { teamsLoading, teamsError, teamsData, teamsRefetch }
}

// League data from the League_READ tab for all leagues including Xmas cup
export function useLeagueData(enabledState) {
  const {
    isLoading: leagueLoading,
    isError: leagueError,
    data: leagueData,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_ALLLEAGUES,
    'leaguedata',
    oneMinute,
    enabledState
  )
  return { leagueLoading, leagueError, leagueData }
}

// H2H data from the H2H_READ tab
export function useH2HData(enabledState) {
  const {
    data: h2hData,
    isLoading: h2hLoading,
    isError: h2hError,
  } = useReactQuery(process.env.REACT_APP_SHEET_ID_READ_H2H, 'h2hdata', oneMinute, enabledState)
  return { h2hData, h2hLoading, h2hError }
}

/********************************************
  
  5   M I N U T E S
 
 ********************************************/

// All player data from the PlayersDB_READ tab
export function usePlayerDataBase(enabledState) {
  const {
    data: dataBaseData,
    isLoading: dataBaseLoading,
    isError: dataBaseError,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_PLAYERS_DB,
    'databasedata',
    fiveMinutes,
    enabledState
  )
  return { dataBaseData, dataBaseLoading, dataBaseError }
}

/********************************************
  
  1 0   M I N U T E S
 
 ********************************************/

// Gameweek data from the Gameweeks_READ tab, indiciating all the date and time details for each game week
export function useGwData(enabledState) {
  const {
    isLoading: gwLoading,
    isError: gwError,
    data: gwData,
    refetch: gwRefetch,
    isFetching: gwIsRefetching,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_GAMEWEEKS,
    'gameweekdata',
    tenMinutes,
    enabledState
  )
  return { gwLoading, gwError, gwData, gwRefetch, gwIsRefetching }
}

/********************************************
  
  3 0   M I N U T E S
 
 ********************************************/

// Max team data from the MaxTeamAllowance_READ tab
export function useFixturesData(enabledState) {
  const {
    data: fixturesData,
    isLoading: fixturesLoading,
    isError: fixturesError,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_GW_FIXTURES,
    'fixturesdata',
    thirtyMinutes,
    enabledState
  )
  return { fixturesData, fixturesLoading, fixturesError }
}

/********************************************
  
  6 0   D A Y S (pointless but feels good)
 
 ********************************************/

// Season list data from the SeasonLists_READ tab
export function useSeasonListData(enabledState) {
  const {
    data: seasonListData,
    isLoading: seasonListLoading,
    isError: seasonListError,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_SEASONLIST,
    'seasonlistdata',
    sixtyDays,
    enabledState
  )
  return { seasonListData, seasonListLoading, seasonListError }
}

// Hall of Fame data from the HallOfFame_READ tab
export function useHOFData(enabledState) {
  const {
    data: hofData,
    isLoading: hofLoading,
    isError: hofError,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_HALLOFFAME,
    'halloffamedata',
    sixtyDays,
    enabledState
  )
  return { hofData, hofLoading, hofError }
}

// Summer transfers data from the AuctionData_READ tab
export function useSummerAuctionData(enabledState) {
  const {
    data: summerAuctionData,
    isLoading: summerAuctionLoading,
    isError: summerAuctionError,
  } = useReactQuery(
    process.env.REACT_APP_SHEET_ID_READ_AUCTIONDATA,
    'summerauctiondata',
    sixtyDays,
    enabledState
  )
  return { summerAuctionData, summerAuctionLoading, summerAuctionError }
}
