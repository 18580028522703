import * as React from 'react'
const SvgNavBalance = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={14} height={24} fill='none' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M7 1v22M12 5H4.5a3.5 3.5 0 1 0 0 7h5a3.5 3.5 0 1 1 0 7H1'
    />
  </svg>
)
export default SvgNavBalance
