import React from 'react'
import styled from 'styled-components'
import { Card, CardContainer, CardH1, CardH2, CardH3 } from 'components/design-elements/Card.styled'
import { PlayerImage } from 'components/patterns/PlayerImage'
import { LargeFont, LittleFont } from './Dash.styled'

const LeaguePoints = ({ weekscore, topPlayerPtsId, topPlayerPts, topPlayer }) => {
  return (
    <>
      <CardH2>League</CardH2>
      <Grid>
        <WeekPoints>
          <LargeFont>{weekscore}</LargeFont>
          <LittleFont>point{weekscore > 1 ? 's' : null}</LittleFont>
        </WeekPoints>
        <Image>
          <PlayerImage source={topPlayerPtsId} width={'70px'} />
        </Image>
        <PlayerPoints>
          <Label>
            {topPlayerPts}pt{weekscore > 1 ? 's' : null}
          </Label>
          <SecondName>{topPlayer}</SecondName>
        </PlayerPoints>
        <Name></Name>
      </Grid>
    </>
  )
}

const BenchPoints = ({ benchweek, topBenchPlayerId, topBenchPlayerPts, topBenchPlayer }) => {
  return (
    <>
      <CardH2>Bench</CardH2>
      <Grid>
        <WeekPoints>
          <LargeFont>{benchweek}</LargeFont>
          <LittleFont>point{benchweek > 1 ? 's' : null}</LittleFont>
        </WeekPoints>
        <Image>
          <PlayerImage source={topBenchPlayerId} width={'70px'} />
        </Image>
        <PlayerPoints>
          <Label>
            {topBenchPlayerPts}pt{benchweek > 1 ? 's' : null}
          </Label>
          <SecondName>{topBenchPlayer}</SecondName>
        </PlayerPoints>
        <Name></Name>
      </Grid>
    </>
  )
}

export default function MyPoints({ userDashData, gameWeek }) {
  const weekscore = Number(userDashData[0].weekscore)
  const benchweek = Number(userDashData[0].benchweek)
  const topPlayer = userDashData[0].topPlayer
  const topPlayerPts = Number(userDashData[0].topPlayerPts)
  const topPlayerPtsId = userDashData[0].topPlayerId
  const topBenchPlayer = userDashData[0].topBenchPlayer
  const topBenchPlayerPts = Number(userDashData[0].topBenchPlayerPts)
  const topBenchPlayerId = userDashData[0].topBenchPlayerId

  return (
    <>
      <Card>
        <CardContainer dashboard>
          <CardH3>My Team</CardH3>
          <CardH1>Game Week {gameWeek}</CardH1>

          {weekscore > 0 && (
            <LeaguePoints
              weekscore={weekscore}
              topPlayer={topPlayer}
              topPlayerPts={topPlayerPts}
              topPlayerPtsId={topPlayerPtsId}
            />
          )}

          {benchweek > 0 && (
            <BenchPoints
              benchweek={benchweek}
              topBenchPlayerId={topBenchPlayerId}
              topBenchPlayerPts={topBenchPlayerPts}
              topBenchPlayer={topBenchPlayer}
            />
          )}
        </CardContainer>
      </Card>
    </>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 1fr;
  grid-template-areas: 'weekpoints image playerpoints';
`
const WeekPoints = styled.div`
  grid-area: weekpoints;
  align-self: end;
  div {
    font-weight: 700;
    font-size: 3em;
  }
`
const Image = styled.div`
  grid-area: image;
  align-self: end;
  justify-self: end;
  padding: 0 1em;
`
const PlayerPoints = styled.div`
  grid-area: playerpoints;
  font-size: 1.2rem;
  font-weight: 800;
  align-self: end;
`
const Label = styled.div`
  grid-area: label;
  align-self: end;
`
const Name = styled.div`
  grid-area: name;
  align-self: end;
`
const SecondName = styled.div`
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
  overflow-wrap: break-word;
`
