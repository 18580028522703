import * as React from 'react'
const SvgTransferWht = ({ scale = 1, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16 * scale}
    height={16 * scale}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='m14.96 5.208-3.572 3.389a.894.894 0 0 1-1.207 0 .787.787 0 0 1 0-1.146L12.29 5.44H1.915c-.476 0-.853-.359-.853-.81 0-.45.377-.81.853-.81H12.29l-2.109-2a.787.787 0 0 1 0-1.145.883.883 0 0 1 1.219 0l3.56 3.377c.158.15.255.359.255.578 0 .22-.085.428-.256.579M.61 10.516l3.572-3.39a.894.894 0 0 1 1.206 0c.33.313.33.833 0 1.146L3.28 10.284h10.375c.476 0 .854.359.854.81 0 .45-.378.81-.854.81H3.28l2.11 2c.328.313.328.833 0 1.145a.883.883 0 0 1-1.22 0L.61 11.672a.8.8 0 0 1-.255-.578c0-.22.085-.428.256-.578'
    />
  </svg>
)
export default SvgTransferWht
