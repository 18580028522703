import styled, { css } from 'styled-components'
import React from 'react'
import { filterByKeys } from 'utils/filterUtils'
import { Card } from 'components/design-elements/Card.styled'

export default function DisplayBalance({ dashData, userName }) {
  const keysToInclude = ['name', 'allowance', 'transfersSpent', 'transfersRemaining']
  const filteredBalanceData = filterByKeys(dashData, keysToInclude)

  const balance = filteredBalanceData.filter(manager => manager.name === userName)
  const allowance = Number(balance[0].allowance)
  const spent = Number(balance[0].transfersSpent)
  const remaining = Number(balance[0].transfersRemaining)
  const balanceArray = Array.from({ length: allowance }, (_, i) => i + 1)
  const balanceValues = balanceArray.map(value => {
    return <Dot key={value} spent={spent} num={value} />
  })

  return (
    <Card>
      <HeaderContainer>
        <BalanceHeader>Transfer Balance</BalanceHeader>
      </HeaderContainer>

      <BalanceContainer>
        <TransferBalance>
          <ValueLabelContainer left>
            <Label>USED</Label>
            <Value>{spent}</Value>
          </ValueLabelContainer>
          <ValueLabelContainer right>
            <Value>{remaining}</Value>
            <Label>REMAINING</Label>
          </ValueLabelContainer>
        </TransferBalance>
        <InfoGraphicContainer>
          <InfoGraph>
            <InfoGraphicGrid allowance={allowance}>{balanceValues}</InfoGraphicGrid>
          </InfoGraph>
          <Key1>0</Key1>
          <Key2>{allowance / 2}</Key2>
          <Key3>{allowance}</Key3>
        </InfoGraphicContainer>
      </BalanceContainer>
    </Card>
  )
}

const HeaderContainer = styled.div`
  padding: 0.8rem 1rem;
  background: var(--color-grey-1);
  border-bottom: 1px solid #e9e9e9;
`
const BalanceHeader = styled.h3`
  font-weight: 800;
  font-size: 0.9rem;
`
const BalanceContainer = styled.div`
  padding: 1rem;
`
const TransferBalance = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
`
const ValueLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  ${props =>
    props.left &&
    css`
      justify-self: start;
    `}
  ${props =>
    props.right &&
    css`
      justify-self: end;
    `}
`
const Value = styled.span`
  font-size: 1.5rem;
  font-weight: 800;
`
const Label = styled.span`
  font-size: 1rem;
  font-weight: 800;
`

const InfoGraphicContainer = styled.div`
  width: 100%;
  display: grid;
  margin: 0 auto;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'infoGraph infoGraph infoGraph'
    'key1 key2 key3';
`
const InfoGraph = styled.div`
  grid-area: infoGraph;
  width: 100%;
  display: grid;
  place-items: center;
`
const Key1 = styled.div`
  grid-area: key1;
  font-size: 0.6rem;
  margin-top: 5px;
  font-weight: 600;
  justify-self: left;
`
const Key2 = styled(Key1)`
  grid-area: key2;
  justify-self: center;
`
const Key3 = styled(Key1)`
  grid-area: key3;
  justify-self: right;
`
const InfoGraphicGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 3px;
  grid-template-rows: 12px;
  grid-template-columns: ${props => `repeat(${props.allowance}, 1fr)`};
`

const Dot = styled.div`
  background: purple;
  border-radius: 3px;
  background: ${props => (props.num <= props.spent ? 'var(--color-secondary)' : '#ccc')};
`
