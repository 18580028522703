import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { VersionFlagsProvider } from './contexts/FlagsContext'
import { DataProvider } from 'contexts/DataContext'
import * as Sentry from '@sentry/react'
import App from './App'
// import Soon from 'views/utility/Soon'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'

Sentry.init({
  dsn: 'https://92a879318040dc2a738f602580e19b99@o4507454049288192.ingest.us.sentry.io/4507454054596608',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <DataProvider>
          <VersionFlagsProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </VersionFlagsProvider>
        </DataProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
