import React, { useContext } from 'react'
import { Data } from 'contexts/DataContext'
import styled from 'styled-components'
import { CardContainer } from 'components/design-elements/Card.styled'
import Avatar from 'components/design-elements/Avatar'

const monthLongNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const date = new Date()
const lastMonthShort = monthShortNames[date.getMonth() - 1] || monthShortNames[11] // gets the previous month short name using index with escape hatch for December
const lastMonthLong = monthLongNames[date.getMonth() - 1] || monthLongNames[11] // as above but gets the previous month long name

const lastMonthColumn = 'mom' + lastMonthShort // appends the previous month short name to the 'mom' string

export default function DashMoM({ dashData }) {
  const { privateDb } = useContext(Data)
  const showMom = privateDb.controls.mom.showMom // boolean to toggle MoM on and off from dB
  const showMomforDays = privateDb.controls.mom.days // sets the number of days the MoM should be shown

  const isFirstWeekOfMonth = new Date().getDate() <= showMomforDays // checks if the current day of the month is in the first week of the month

  let winnerArray = []
  dashData.map(row =>
    winnerArray.push({
      name: row.name,
      profileImage: row.profileImage,
      points: Number(row[lastMonthColumn]),
    })
  )

  // finds the highest points scoring manager(s) in the last month and returns an array of objects
  const maxMomPoints = managers => {
    const maxPoints = Math.max(...managers.map(manager => manager.points))
    return managers.filter(manager => manager.points === maxPoints)
  }

  const latestMomWinnerData = maxMomPoints(winnerArray)
  const latestMomWinnerComponent = latestMomWinnerData.map(winner => {
    return (
      <WinnersGrid key={winner.name}>
        <Name>{winner.name}</Name>
        <ProfileImage>
          <Avatar manager={winner.name} size='70px' />
        </ProfileImage>
        <Points>{winner.points}pts</Points>
      </WinnersGrid>
    )
  })

  const moreThanOneWinner = latestMomWinnerData.length > 1
  const addS = moreThanOneWinner ? 's' : null

  return (
    <>
      {showMom && isFirstWeekOfMonth && (
        <Card>
          <CardContainer dashboard>
            <MomGrid>
              {moreThanOneWinner ? (
                <DoubleAwardContainer>
                  <DoubleAward top='35px' left='23px' />
                  <DoubleAward top='70px' left='45px' />
                </DoubleAwardContainer>
              ) : (
                <Award />
              )}
              <Header>Manager{addS} of the month</Header>
              <Month>{lastMonthLong}</Month>
              <Winners>{latestMomWinnerComponent}</Winners>
            </MomGrid>
          </CardContainer>
        </Card>
      )}
    </>
  )
}

const Card = styled.div`
  position: relative;
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);
  background: center / contain no-repeat url('img/mom/mom_bg.svg');
  background-size: cover;
  margin: 2em 0 1rem;
  /* padding: 2em 1em 1.2em 2em; */
`

const MomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2px 0;
  grid-template-areas:
    'award header header'
    'award month month'
    'award winners winners';
`
const Winners = styled.div`
  grid-area: winners;
`
const WinnersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2px 0;
  padding: 5px 0;
  grid-template-areas:
    'name profile'
    'points profile';
`
const Award = styled.img.attrs({
  src: 'img/mom/mom.png',
})`
  grid-area: award;
  width: 72px;
  margin-top: -50px;
`
const DoubleAwardContainer = styled.div`
  grid-area: award;
`
const DoubleAward = styled.img.attrs({
  src: 'img/mom/mom.png',
})`
  width: 62px;
  position: absolute;
  top: ${props => props.top || `0`};
  left: ${props => props.left || `0`};
`
const Header = styled.div`
  grid-area: header;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 0.9;
`
const Month = styled.div`
  grid-area: month;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
`
const Name = styled.div`
  grid-area: name;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 900;
  align-self: end;
`
const ProfileImage = styled.div`
  grid-area: profile;
`
const Points = styled.div`
  grid-area: points;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  align-self: start;
`
