import styled, { css } from 'styled-components'

export const Card = styled.div`
  position: relative;
  margin-bottom: 1em;
  background: white;
  border-radius: var(--box-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
`
export const CardContainer = styled.div`
  padding: 1rem;
  ${({ dashboard }) =>
    dashboard &&
    css`
      padding: 2rem;
    `}
`
export const CardH1 = styled.h1`
  font-size: 1.3rem;
  font-weight: 900;
  color: #000000;
  font-style: normal;
  text-align: left;
  margin: 0 0 25px 0;
  text-transform: capitalize;
`
export const CardH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  margin: 20px 0 0 0;
  text-transform: capitalize;
`

export const CardH3 = styled.h3`
  font-size: 0.8rem;
  font-weight: 800;
  color: #000;
  margin: 0 0 3px 0;
`
