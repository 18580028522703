import { useContext } from 'react'
import useVersionFlag from 'hooks/useVersionFlag'
import { Data } from 'contexts/DataContext'

export default function useAvatar() {
  const { isUK } = useVersionFlag()
  const { privateDb } = useContext(Data)

  const users = privateDb.users
  const region = isUK ? 'UK' : 'US'

  const getPhotoURL = name =>
    Object.values(users)
      .filter(user => user.region === region && user.name === name)
      .map(user => user.photoURL)
      .shift()

  return { getPhotoURL }
}
