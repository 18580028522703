import * as React from 'react'
const SvgNavTeams = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={35} height={39} fill='none' {...props}>
    <path
      fill='currentColor'
      d='M2.785 36v-6.358H.32v-1.397h6.644v1.397H4.5V36zm6.939.121q-1.452 0-2.266-.78-.814-.783-.814-2.124 0-.87.34-1.518.353-.65.969-1.012.626-.375 1.463-.374.825 0 1.375.352.56.353.836.99.285.627.286 1.463v.385H8.008v-.825h2.695l-.187.154q0-.693-.286-1.045-.275-.363-.803-.363-.584 0-.902.43-.308.428-.308 1.231v.176q0 .814.396 1.21.407.396 1.166.396.45 0 .847-.11.407-.12.77-.374l.462 1.1a3.1 3.1 0 0 1-.968.473q-.54.165-1.166.165m5.41 0q-.726 0-1.276-.352a2.35 2.35 0 0 1-.858-1q-.308-.65-.308-1.563 0-.902.308-1.55.307-.651.858-.99a2.3 2.3 0 0 1 1.276-.353q.67 0 1.199.33.539.319.704.87h-.121l.132-1.09h1.595l-.066.737q-.022.363-.022.715V36h-1.65l-.011-1.045h.132q-.176.528-.704.847a2.25 2.25 0 0 1-1.188.32m.506-1.254q.571 0 .924-.407.352-.407.352-1.254t-.352-1.243-.924-.396-.924.396-.352 1.243q0 .848.34 1.254.352.407.936.407M19.917 36v-4.125a12 12 0 0 0-.088-1.452h1.595l.12 1.034h-.131q.242-.527.715-.836t1.122-.308q.637 0 1.067.308.44.297.627.935h-.176q.242-.572.77-.902a2.16 2.16 0 0 1 1.199-.34q.945 0 1.408.56t.462 1.76V36h-1.661v-3.31q0-.584-.187-.837t-.605-.253q-.495 0-.781.352t-.286.968V36h-1.661v-3.31q0-.584-.187-.837t-.605-.253q-.495 0-.781.352-.276.352-.275.968V36zm12.08.121q-.75 0-1.365-.165a3.3 3.3 0 0 1-1.045-.462l.43-1.1q.428.264.945.418.528.154 1.045.154.484 0 .715-.154a.48.48 0 0 0 .231-.418.41.41 0 0 0-.154-.34q-.142-.122-.45-.177l-1.134-.209q-.715-.143-1.089-.528t-.374-1q0-.54.286-.947.297-.418.847-.649.55-.23 1.287-.23.627 0 1.188.153.561.154.957.473l-.45 1.067a2.9 2.9 0 0 0-.793-.407 2.6 2.6 0 0 0-.847-.154q-.528 0-.759.176a.5.5 0 0 0-.23.43q0 .197.131.33.144.13.43.197l1.143.21q.738.13 1.122.494.385.363.385 1.001 0 .584-.308.99t-.869.627q-.55.22-1.276.22'
    />
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeWidth={0.5}
      d='m28.755 5.936-5.728-4.779A.68.68 0 0 0 22.593 1H11.407a.68.68 0 0 0-.435.157L5.244 5.936a.68.68 0 0 0-.061.985l3.643 3.91c.242.26.645.288.922.065l.98-.794v10.219c0 .375.304.679.679.679h11.186a.68.68 0 0 0 .678-.68V10.103l.982.794c.276.224.679.195.922-.065l3.643-3.91a.68.68 0 0 0-.063-.985Zm-9.82-3.577-.002.027A1.936 1.936 0 0 1 17 4.32a1.936 1.936 0 0 1-1.933-1.935q0-.014-.003-.027zM22.3 8.068a.68.68 0 0 0-.386.613v10.611h-9.83V8.68a.68.68 0 0 0-1.104-.53L9.563 9.52 6.83 6.573l4.822-4.214h2.06l-.002.027A3.295 3.295 0 0 0 17 5.68a3.295 3.295 0 0 0 3.289-3.294q-.001-.014-.003-.027h2.06l4.823 4.214-2.733 2.947-1.417-1.368a.68.68 0 0 0-.719-.084Z'
    />
  </svg>
)
export default SvgNavTeams
