import * as React from 'react'
const SvgNavLive = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M13 2 3 14h9l-1 8 10-12h-9z'
    />
  </svg>
)
export default SvgNavLive
