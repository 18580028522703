import React, { useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import CustomErrorBoundary from 'views/utility/CustomErrorBoundary'
import { useAuth } from 'contexts/AuthContext'
import { Data } from 'contexts/DataContext'
import DashHeader from './DashHeader'
import DashProfile from './dash-profile/DashProfile'
import DashMoM from './DashMoM'
import DashMyPoints from './DashMyPoints'
import DashMyForm from './DashMyForm'
import DashPendingTransfers from './DashPendingTransfers'
import DashScores from './DashScores'
import DashQuickLinks from './DashQuickLinks'
import DashFixtures from './DashFixtures'
import DashSpendWarning from './DashSpendWarning'
import FourOFour from 'views/utility/FourOFour'
import Loading from 'components/loader/Loading'
import useVersionFlag from 'hooks/useVersionFlag'
import useCanUpdateTeam from 'hooks/useCanUpdateTeam'
import { useDashData, useTransferData, useFixturesData } from 'hooks/useApiCall'
import { AnimatedCard } from 'components/loader/Spinners'
import { PageWrapper } from 'components/design-elements/Wrappers.styled'
import { LoaderContainer } from './Dash.styled'
import DashBackground from './DashBackground'

export default function Dashboard() {
  const { dashData, dashError, dashLoading } = useDashData()
  const { canUpdateTeam } = useCanUpdateTeam()
  const { transferData, transferLoading, transferError } = useTransferData()
  const { fixturesData, fixturesLoading, fixturesError } = useFixturesData()
  const { currentUser } = useAuth()
  const { isUK } = useVersionFlag()
  const { uid, displayName: userName, email } = currentUser
  const { privateDb, fireBaseisLoading } = useContext(Data)

  /* ====================================================================================
  FOR DEVELOPMENT PURPOSES:
  checks whether I have a country flag vs logged in conflict. Uses my UID to check.
  ====================================================================================*/

  useEffect(() => {
    const checkConflict =
      (uid === process.env.REACT_APP_ADMIN_UID_UK && isUK) ||
      (uid === process.env.REACT_APP_ADMIN_UID_USA && !isUK)
        ? [
            `%c${isUK ? ' UK 🇬🇧' : ' USA 🇺🇸'} ✅ PASSED: NO FLAG <> USER CONFLICT `,
            'background: #99ff99; color: green',
          ]
        : [
            '%c ❌ WARNING: FLAG <> USER CONFLICT ',
            'background: #ffdae0; color: red',
            { email, isUK },
          ]
    if (uid === process.env.REACT_APP_ADMIN_UID_UK || uid === process.env.REACT_APP_ADMIN_UID_USA) {
      console.log(...checkConflict)
    }
  }, [])

  if (dashError || transferError) return <FourOFour />
  if (dashLoading || fireBaseisLoading)
    return <Loading dashLoading={dashLoading} fireBaseisLoading={fireBaseisLoading} />

  const userDashData = dashData.filter(firstname => firstname.name === userName)
  const isInAuctionRoom = userDashData[0].inAuctionRoom === 'TRUE'
  const gameWeek = Number(dashData[0].gw)
  const weekscore = Number(userDashData[0].weekscore)
  const benchweek = Number(userDashData[0].benchweek)
  const showMyPoints = weekscore !== 0 || benchweek !== 0

  return (
    <PageWrapper dashboard bottom0>
      <Helmet>
        <meta name='theme-color' content='#AB6E78' />
      </Helmet>
      <DashBackground />
      <CustomErrorBoundary>
        <DashHeader userName={userName} currentUser={currentUser} />
        <DashProfile userName={userName} userDashData={userDashData} />
        <DashSpendWarning dashData={dashData} />
        <DashQuickLinks canUpdateTeam={canUpdateTeam} isInAuctionRoom={isInAuctionRoom} />
        <DashMoM dashData={dashData} privateDb={privateDb} />
        {showMyPoints && <DashMyPoints userDashData={userDashData} gameWeek={gameWeek} />}
        {gameWeek > 3 && <DashMyForm userDashData={userDashData} gameWeek={gameWeek} />}
        {!transferLoading && <DashPendingTransfers transferData={transferData} />}
        {fixturesLoading || dashLoading ? (
          <LoaderContainer>
            <AnimatedCard />
          </LoaderContainer>
        ) : fixturesError || dashError ? (
          'Error loading fixtures and points...'
        ) : (
          <>
            <DashFixtures fixturesData={fixturesData} />
            <DashScores dashData={dashData} gameWeek={gameWeek} />
          </>
        )}
      </CustomErrorBoundary>
    </PageWrapper>
  )
}
