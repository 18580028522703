import styled, { css } from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const InputLabels = styled.label`
  font-size: 1rem;
  font-weight: 800;
  ${({ whiteLabel }) =>
    whiteLabel &&
    css`
      color: white;
    `}
`
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  background: var(--color-grey-1);
  padding: 1rem;
  border-radius: 10px;
`
export const SwitchTextFalse = styled.p`
  font-size: 1rem;
  font-weight: 400;
`
export const SwitchTextTrue = styled(SwitchTextFalse)`
  color: var(--color-primary);
  font-weight: 800;
`
