import React from 'react'
import useAvatar from 'hooks/useAvatar'
import ProfileComponent from './ProfileComponent'
import PointsComponent from './PointsComponent'
import CashTransfersComponent from './CashTransfersComponent'
import { GlassGrid, Profile, Cash, Transfers } from './DashProfile.styled'
import { IconCash, IconTransferWht } from 'icons'

export default function DashProfile({ userName, userDashData }) {
  const { getPhotoURL } = useAvatar()
  const photoURL = getPhotoURL(userName) || ''

  const cash = userDashData[0].cashRemaining
  const transfers = userDashData[0].transfersRemaining
  const starCount = userDashData[0].hofRecord

  return (
    <GlassGrid>
      <Profile>
        <ProfileComponent userName={userName} photoURL={photoURL} starCount={starCount} />
        <PointsComponent userDashData={userDashData} />
      </Profile>
      <Cash>
        <CashTransfersComponent icon={<IconCash scale={0.8} />} label='Cash' value={`${cash}m`} />
      </Cash>
      <Transfers>
        <CashTransfersComponent
          icon={<IconTransferWht scale={1.5} />}
          label='Transfers'
          value={transfers}
        />
      </Transfers>
    </GlassGrid>
  )
}
