import React, { createContext } from 'react'
import flags from 'flags'

export const VersionFlags = createContext({})

const countryFlag = flags[0]

export const VersionFlagsProvider = ({ children }) => {
  return (
    <VersionFlags.Provider value={{ countryFlag }}>
      {children}
    </VersionFlags.Provider>
  )
}
