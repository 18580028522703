import React, { useEffect } from 'react'
import styled from 'styled-components'
import { InputContainer, InputLabels } from './Input.styled'

/*
 *  @param {Array} radios – array containing the radio object options
 *  @param {Object} activeRadio – state object
 *  @param {Function} setActiveRadio – setState function
 *  @param {Function} sortData – data sorting function
 *
 *  @param {String} id – unique string for that specific radio
 *  @param {String} name – unique string state used by radio group (same name as state)
 *  @param {String} label – unique string that will be displayed in the UI
 *  @param {String} value – unique string that will be passed into state
 */

const radioRefs = {}

export default function Radios({
  radios,
  activeRadio,
  setActiveRadio,
  sortDataFn,
  sortedData,
  setSortedData,
  inputLabel,
}) {
  // Function to reset all radio buttons
  const resetRadioButtons = () => {
    Object.values(radioRefs).forEach(radioRef => {
      if (radioRef.current) {
        radioRef.current.checked = false
      }
    })
  }

  // Listen for changes in activeRadio and reset radio buttons
  useEffect(() => {
    resetRadioButtons()
  }, [activeRadio])

  const onRadioChange = e => {
    setActiveRadio(e.target.value)
    sortDataFn(sortedData, setSortedData, e.target.value)
  }

  return (
    <InputContainer>
      <InputLabels>{inputLabel}</InputLabels>
      <RadioGroup>
        {radios.map(radio => (
          <Radio
            key={radio.id}
            id={radio.id}
            name={radio.name}
            label={radio.label}
            value={radio.value}
            disabled={radio.disabled}
            activeRadio={activeRadio}
            onRadioChange={onRadioChange}
            radioRef={radioRefs[radio.value]} // Pass the ref to Radio component
          />
        ))}
      </RadioGroup>
    </InputContainer>
  )
}

function Radio({ id, name, label, value, disabled, activeRadio, onRadioChange, radioRef }) {
  useEffect(() => {
    if (radioRef) {
      radioRef.current.checked = activeRadio === value
    }
  }, [activeRadio, value, radioRef])

  return (
    <>
      <RadioInput
        ref={radioRef}
        type='radio'
        name={name}
        value={value}
        id={id}
        checked={activeRadio === value}
        onChange={onRadioChange}
        disabled={disabled}
      />
      <Label htmlFor={id} checkedState={activeRadio === value}>
        {label}
      </Label>
    </>
  )
}

const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  input[type='radio'] {
    display: none;
  }
`
const RadioInput = styled.input`
  :disabled + label {
    color: #ccc;
  }
`

const Label = styled.label`
  background: var(--color-grey-1);
  font-size: 0.8rem;
  font-weight: 500;
  white-space: pre-line;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 5px;
  transition: 0.3s ease;
  cursor: pointer;
  border: ${({ checkedState }) =>
    checkedState ? '2px solid var(--color-primary)' : '2px solid var(--color-grey-1)'};
  box-shadow: ${({ checkedState }) =>
    checkedState ? '0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc' : 'none'};
`
