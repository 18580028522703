import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useMatch } from 'react-router-dom'
import { SvgInfo } from 'styles/SvgIcon'
import { NotificationIcon } from 'components/design-elements/Notifications'
import { IconFilter } from 'icons'

export default function NavigationTabs({
  tab1,
  tab2,
  tab2url,
  pathname,
  isSticky = true,
  showFilter = false,
  showInfo = false,
  onClickFunc,
}) {
  const [isScrolling, setIsScrolling] = useState({
    scrollingDown: false,
    scrollPosition: window.scrollY,
  })

  useEffect(() => {
    const prevScrollPos = window.scrollY
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      setIsScrolling({
        ...isScrolling,
        scrollingDown: currentScrollPos > prevScrollPos,
        scrollPosition: currentScrollPos,
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolling])

  return (
    <NavBackground isSticky={isSticky} isScrolling={isScrolling}>
      <NavItems>
        <TabItem to={''} pathname={pathname}>
          {tab1}
        </TabItem>
        <TabItem to={tab2url} pathname={pathname}>
          {tab2}
        </TabItem>
      </NavItems>
      {showFilter && (
        <NavIcon onClick={onClickFunc}>
          <IconFilter />
        </NavIcon>
      )}
      {showInfo && (
        <NavIcon onClick={onClickFunc}>
          <NotificationIcon style={{ marginInlineEnd: '0' }}>
            <SvgInfo />
          </NotificationIcon>
        </NavIcon>
      )}
    </NavBackground>
  )
}

const NavBackground = styled.div`
  height: 45px;
  top: ${props => (props.isScrolling.scrollingDown && props.isSticky ? '-50px' : '0')};
  transition: top 0.5s;
  position: ${props => (props.isSticky ? 'sticky' : 'relative')};
  z-index: 1;
  backdrop-filter: blur(10px);
  background: #eeeeee90;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 1.2rem;
`

const NavItems = styled.div`
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
`
const NavIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

const TabItem = ({ to, children, pathname }) => {
  const match = Boolean(useMatch(`${pathname}/${to}`))

  const handleScroll = () => {
    window.scrollTo(0, 0)
  }

  return (
    <TabContainer onClick={handleScroll}>
      <NavLink to={to}>
        <TabLabel>{children}</TabLabel>
      </NavLink>
      <ActiveTab isActive={match} />
    </TabContainer>
  )
}

const TabContainer = styled.button`
  position: relative;
  height: 100%;
  display: flex;
  background: transparent;
  align-items: center;
  border: none;
  padding: 0;
  cursor: pointer;
`

const TabLabel = styled.h2`
  font-weight: 800;
  text-transform: uppercase;
  font-size: 0.9rem;
  display: inline;
`

const ActiveTab = styled.span`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #000;
  border-radius: 16px;
  height: ${props => (props.isActive ? '4px' : '0px')};
  opacity: ${props => (props.isActive ? '1' : '0')};
  transition: all 0.3s ease-out;
  transform: none;
`
