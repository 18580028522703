import * as React from 'react'
const SvgEmptyStateNothingInList = ({ scale = 1, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={111 * scale}
    height={103 * scale}
    viewBox='0 0 111 103'
    fill='none'
    {...props}
  >
    <path fill='#939BA5' d='m20.5 34.5 34-18.5 34 18.5-34 21z' />
    <path
      fill='#000'
      d='M90.264 37.862c-.28 0-.839 0-1.118-.28L55.052 18.022 20.96 37.583c-1.118.558-2.515.279-3.074-.839s-.28-2.515.838-3.074l35.212-20.4c.558-.28 1.676-.28 2.235 0l35.212 20.4c1.117.559 1.397 1.956.838 3.074-.559.559-1.118 1.118-1.956 1.118M55.052 102.975c-.279 0-.838 0-1.117-.28l-35.212-20.12c-.559-.28-1.117-1.118-1.117-1.957V55.748c0-1.118 1.117-2.236 2.235-2.236s2.236 1.118 2.236 2.236v23.474l32.975 19.003 32.976-19.003V55.747c0-1.118 1.118-2.236 2.236-2.236s2.235 1.118 2.235 2.236v24.871c0 .839-.558 1.677-1.117 1.957l-35.212 20.4z'
    />
    <path
      fill='#000'
      d='M55.052 102.975c-1.117 0-2.235-1.118-2.235-2.236V57.144L18.723 37.583c-1.117-.56-1.397-1.957-.838-3.074.559-1.118 1.956-1.398 3.074-.839l35.211 20.4c.56.28 1.118 1.118 1.118 1.957v44.992c0 1.118-1.118 1.956-2.236 1.956'
    />
    <path
      fill='#000'
      d='M55.054 57.983c-.838 0-1.397-.28-1.956-1.118-.56-1.118-.28-2.515.838-3.074l35.211-20.4c1.118-.56 2.516-.28 3.075.838.558 1.118.279 2.515-.839 3.074l-35.211 20.4c-.28.28-.838.28-1.118.28'
    />
    <path
      fill='#000'
      d='M90.265 37.862c-.838 0-1.397-.28-1.956-1.118-.559-1.118-.28-2.515.838-3.074l14.253-8.104L72.66 7.96l-16.488 9.502c-1.118.559-2.515.28-3.074-.839-.56-1.117-.28-2.515.838-3.074L71.542 3.49c.559-.28 1.677-.28 2.236 0l35.211 20.4c.559.28 1.118 1.118 1.118 1.956 0 .839-.559 1.677-1.118 1.957l-17.606 9.501c-.28.28-.838.559-1.118.559'
    />
    <path
      fill='#000'
      d='M19.841 37.862c-.28 0-.838 0-1.117-.28L1.117 27.523C.558 27.242 0 26.404 0 25.566s.559-1.677 1.118-1.956l35.211-20.4c.559-.28 1.677-.28 2.236 0L56.17 13.27c1.118.559 1.398 1.956.839 3.074-.56 1.118-1.956 1.397-3.074.838L37.447 7.681 6.707 25.287l14.252 8.104c1.118.559 1.397 1.956.838 3.074-.279.838-1.117 1.397-1.956 1.397'
    />
    <path
      fill='#000'
      d='M37.447 68.043c-.28 0-.838 0-1.118-.28l-35.211-20.4C.558 47.085 0 46.247 0 45.408c0-.838.559-1.676 1.118-1.956l17.606-10.06c1.117-.56 2.515-.28 3.073.838.56 1.118.28 2.515-.838 3.074L6.707 45.407l30.74 17.606 16.488-9.501c1.118-.56 2.515-.28 3.074.838s.28 2.515-.839 3.074l-17.605 10.06c-.28.56-.839.56-1.118.56'
    />
    <path
      fill='#000'
      d='M72.66 68.043c-.28 0-.839 0-1.118-.28l-17.606-10.06c-1.118-.559-1.397-1.956-.838-3.074s1.956-1.397 3.074-.838l16.488 9.501 30.74-17.605-14.253-8.104c-1.117-.56-1.397-1.957-.838-3.074.559-1.118 1.956-1.398 3.074-.839l17.606 10.06c.559.28 1.118 1.118 1.118 1.957 0 .838-.559 1.677-1.118 1.956l-35.211 20.4z'
    />
  </svg>
)
export default SvgEmptyStateNothingInList
